//import { ACPermissionConstants } from "./ACPermissionConstants";
//import { getWorkspaceLeftMenuItems1 } from "./ACutils";
import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { msalInstance } from "../..";
import { EntityRow } from "../../RAFMaster/RMModules/Entity/EntityRow";
import CreateAccount from "../../RAFModules/ActiveContacts/Account/CreateAccount";
import UpdateAccount from "../../RAFModules/ActiveContacts/Account/UpdateAccount";
import CreateCity from "../../RAFModules/ActiveContacts/City/CreateCity";
import CreateContact from "../../RAFModules/ActiveContacts/Contact/CreateContact";
import UpdateContact from "../../RAFModules/ActiveContacts/Contact/UpdateContact";
import CreateCountry from "../../RAFModules/ActiveContacts/Country/CreateCountry";
import CreateDeal from "../../RAFModules/ActiveContacts/Deal/CreateDeal";
import UpdateDeal from "../../RAFModules/ActiveContacts/Deal/UpdateDeal";
import CreateState from "../../RAFModules/ActiveContacts/State/CreateState";
import CreateTicket from "../../RAFModules/ActiveContacts/Ticket/CreateTicket";
import UpdateTicket from "../../RAFModules/ActiveContacts/Ticket/UpdateTicket";
import ManageUser from "../../RAFModules/ActiveContacts/User/ManageUser";
import ManageUserGroup from "../../RAFModules/ActiveContacts/UserGroup/ManageUserGroup";
import ManageCareServiceContract from "../../RAFModules/CareESIO/CareServiceContract/ManageCareServiceContract";
import ManageChecklistTemplateItem from "../../RAFModules/CareESIO/ChecklistTemplate/ChecklistTemplateItem/ManageChecklistTemplateItem";
import CreateChecklistTemplate from "../../RAFModules/CareESIO/ChecklistTemplate/CreateChecklistTemplate";
import ManageChecklistTemplate from "../../RAFModules/CareESIO/ChecklistTemplate/ManageChecklistTemplate";
import ManageEmployee from "../../RAFModules/CareESIO/Employee/ManageEmployee";
import ManagePayItem from "../../RAFModules/CareESIO/PayItem/ManagePayItem";
import RAFCreate from "../../RAFModules/Common/Create";
import RAFManageStandardizedLibrary from "../../RAFModules/Common/StandardizedLibrary/RAFManageStandardizedLibrary";
import RAFEdit from "../../RAFModules/Common/Update";
import { WorkspaceItemRow } from "../../RAFModules/Common/Workspace/WorkspaceItem/WorkspaceItemRow";
import { WorkspaceRow } from "../../RAFModules/Common/Workspace/WorkspaceRow";
import { CareEsioEntity } from "../../constants/CareESIO/CareEsioConstants";
import { ContentType, RAFButtonConstant, RAFReportType, RAFTenantName, RAFTreeViewMenu, StorageKey } from "../../constants/Common/Constants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { RAFWorkspaceRelatedtoType, RMStorageKey } from "../../constants/Common/RMConstants";
import { MenuCategory } from "../../constants/MenuConstant/RAFMenuCategory";
import { getModuleAvatarIcon } from "../../helpers/Common/AvatarHelper";
import { FocusRow } from "../../pages/FocusRow";
import { RAFCustomFilter, RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { FocusServiceRequest, ListServiceRequest } from "../models/Common/ListRequest";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";
import { MenuRow } from "../models/Common/RAFMenuModel";
import { RolePermissionRow } from "../models/Common/RolePermissionRow";
import * as repositoryActions from "./../store/actions/repositoryActions";
import { ISESIOAppAdministrator, IsAdminQueryString, IsSuperAdmin, getFields, getSessionStorage, isCurrentTenantCareESIOPartner, setSessionStorage } from "./AppHelper";
import { hasPermission } from "./PermissionHelper";
import { RAFSort } from "./types";
import { ConvertCollectionName, ConvertSystemName, ConvertToPascal, Guid, IsNotNullOrWhiteSpace, IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined, isNullOrUndefined, propertyOf } from "./utils";

export function GetPortalModuleMenuItems() {
    const allLocalMainMenuItems: MenuRow[] = [];
    allLocalMainMenuItems.push(
        //{ Name: RAFEntityName.ServiceTransaction, Text: "Schedule", Url: "/Schedule", "Icon": getModuleAvatarIcon(RAFEntityName.Task) },
        { Name: RAFEntityName.ServiceTransaction, Text: "Bookings", Url: "/Bookings", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },//my_shift
        { Name: RAFEntityName.ServiceTransaction, Text: "Roster", Url: "/Roster", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false }, //all_shift
        { Name: RAFEntityName.ServiceTransaction, Text: "Shift Offers", Url: "/ShiftOffers", "Icon": getModuleAvatarIcon(RAFEntityName.ShiftOffer), HasChildren: false },
        { Name: "Payroll", Text: "Payroll", Url: "/Payroll", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },
        { Name: "Billing", Text: "Billing", Url: "/Billing", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },
        { Name: "XeroSync", Text: "Xero Sync", Url: "/XeroSync", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },
        { Name: "SupportLog", Text: "Support Log", Url: "/Supportlog", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },
        { Name: RAFEntityName.Contact, Text: "Contacts", Url: getListPageUrlByModuleName(RAFEntityName.Contact), Icon: getModuleAvatarIcon(RAFEntityName.Contact) },
        { Name: RAFEntityName.Account, Text: "Companies", Url: getListPageUrlByModuleName(RAFEntityName.Account), "Icon": getModuleAvatarIcon(RAFEntityName.Account) },
        { Name: RAFEntityName.Deal, Text: "Deals", Url: getListPageUrlByModuleName(RAFEntityName.Deal), "Icon": getModuleAvatarIcon(RAFEntityName.Deal) },
        { Name: RAFEntityName.Ticket, Text: "Tickets", Url: getListPageUrlByModuleName(RAFEntityName.Ticket), "Icon": getModuleAvatarIcon(RAFEntityName.Ticket) },
        { Name: RAFEntityName.ContentLibrary, Text: "Company Documents", Url: "/document/list/", Icon: getModuleAvatarIcon(RAFEntityName.ContentLibrary) },
        { Name: RAFEntityName.AcknowledgementTracking, Text: "Acknowledgement Tracking", Url: "/raf/list/acknowledgement_tracking/", Icon: getModuleAvatarIcon(RAFEntityName.AcknowledgementTracking) },
        { Name: CareEsioEntity.ChecklistItemTrans.EntityName, Text: "Compliance Tracking", Url: "/compliance/list/", Icon: getModuleAvatarIcon(CareEsioEntity.ChecklistItemTrans.EntityName), HasChildren: false },
        { Name: RAFEntityName.Email, Text: "Emails", Url: "/email/list/", Icon: getModuleAvatarIcon(RAFEntityName.Email) },
        //{ Name: RAFEntityName.FormLibrary, Text: "Form Library", Url: "/formLibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.FormLibrary) },
        { Name: RAFEntityName.FormLibraryStatic, Text: "Form Library", Url: "/formLibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.FormLibraryStatic) },
        { Name: RAFEntityName.BusinessProcess, Text: "Process Monitor", Url: "/processmonitor/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate) },
        { Name: RAFEntityName.BusinessProcessTemplate, Text: "Forms & Flow", Url: "/formflow/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate) },
        //{ Name: `assessment_${RAFEntityName.FormLibrary}`, Text: "Assessments", Url: "/assessment/list", "Icon": getModuleAvatarIcon(RAFEntityName.FormLibrary) },
        //{ Name: `content_type_${RAFEntityName.Category}`, Text: "Content Type Library", Url: "/contenttype/list", "Icon": getModuleAvatarIcon(RAFEntityName.Category) },
        { Name: RAFEntityName.Report, Text: "Reports & Analytics", Url: "/Report/List", "Icon": getModuleAvatarIcon(RAFEntityName.Report), HasChildren: false },
        { Name: "QualityAndSafety", Text: "Quality & Safety", Url: "/Quality/List", "Icon": getModuleAvatarIcon(RAFEntityName.Task), HasChildren: false },
        { Name: RAFEntityName.BusinessRule, Text: "Automations", Url: "/automations/List", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessRule), HasChildren: false },
        { Name: RAFEntityName.ProcessLibrary, Text: "Process Library", Url: "/processlibrary/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate) },
    );

    if (msalInstance.currentTenantName === RAFTenantName.Propelsa) {
        allLocalMainMenuItems.push(
            { Name: "Membership", Text: "Membership", Url: getListPageUrlByModuleName('membership'), "Icon": getModuleAvatarIcon("Membership") },
        );
    }

    return allLocalMainMenuItems;
};

export const getListPageUrlByModuleName = (moduleName: string) => {
    let url;
    if (!IsNullOrWhiteSpace(moduleName)) {
        const entityName = getURLNameByModuleName(moduleName, false);
        if (isNotNullAndUndefined(entityName) && msalInstance.currentTenantName === RAFTenantName.skytrust) {
            url = `/${entityName.toLowerCase()}/list/ `;
        } else {
            url = `/raf/list/${moduleName.toLowerCase()}/`;
        }
    }
    return url;
};

export function GetDefaultAdminNavigationMenuItems() {

    const allLocalAdminMenuItems: MenuRow[] = [];

    allLocalAdminMenuItems.push(
        { Name: RAFEntityName.Team, Entity: RAFEntityName.Team, Text: "Teams", Url: "/team/list", Icon: getModuleAvatarIcon(RAFEntityName.Team), HasChildren: false },
        { Name: RAFEntityName.Role, Entity: RAFEntityName.Role, Text: "Permissions", Url: "/role/list", Icon: getModuleAvatarIcon(RAFEntityName.Role), HasChildren: false },
        { Name: RAFEntityName.Portal, Entity: RAFEntityName.Portal, Text: "Portals", Url: "/portal/list", Icon: getModuleAvatarIcon(RAFEntityName.Portal), HasChildren: false },
        { Name: RAFEntityName.StandardizedLibrary, Text: 'Select List', Entity: RAFEntityName.StandardizedLibrary, Url: getListPageUrlByModuleName(RAFEntityName.StandardizedLibrary), "Icon": getModuleAvatarIcon(RAFEntityName.StandardizedLibrary) },
        { Name: RAFEntityName.FormLibraryAdmin, Text: "Form Library", Url: "/formLibrary/list/admin", Icon: getModuleAvatarIcon(RAFEntityName.FormLibrary), Entity: RAFEntityName.FormLibraryAdmin, HasChildren: true },
        { Name: RAFEntityName.ProcessLibrary, Text: "Process Library", Url: "/processlibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.ProcessLibrary), Entity: RAFEntityName.ProcessLibrary, HasChildren: false },
    );

    if (msalInstance.currentTenantName === RAFTenantName.CareESIO || isCurrentTenantCareESIOPartner() === true) {
        allLocalAdminMenuItems.push(
            { Name: "PriceList", Text: "Service Price List", Entity: CareEsioEntity.CarePriceList.EntityName, Url: getListPageUrlByModuleName(CareEsioEntity.CarePriceList.EntityName), "Icon": getModuleAvatarIcon(CareEsioEntity.CarePriceList.EntityName), HasChildren: false },
            { Name: "PayItem", Text: "Pay Item", Entity: CareEsioEntity.CarePayItem.EntityName, Url: getListPageUrlByModuleName(CareEsioEntity.CarePayItem.EntityName), "Icon": getModuleAvatarIcon(CareEsioEntity.CarePayItem.EntityName), HasChildren: false },
            { Name: "PublicHolidays", Text: "Public Holidays", Entity: CareEsioEntity.CarePublicHolidays.EntityName, Url: getListPageUrlByModuleName(CareEsioEntity.CarePublicHolidays.EntityName), "Icon": getModuleAvatarIcon(CareEsioEntity.CarePublicHolidays.EntityName), HasChildren: false },
            { Name: "ChecklistTemplate", Text: "Requirements Template", Entity: CareEsioEntity.ChecklistTemplate.EntityName, Url: getListPageUrlByModuleName(CareEsioEntity.ChecklistTemplate.EntityName), "Icon": getModuleAvatarIcon(CareEsioEntity.ChecklistTemplate.EntityName), HasChildren: false },
            { Name: "XeroIntegration", Text: "Integrations", Entity: CareEsioEntity.XeroIntegration.EntityName, Url: `/Integration`, "Icon": getModuleAvatarIcon(CareEsioEntity.CarePriceList.EntityName), HasChildren: false },
        );
    }
    allLocalAdminMenuItems.push(
        { Name: RAFEntityName.TenantSettings, Entity: RAFEntityName.TenantSettings, Text: "Settings", Url: "/tenantSettings", Icon: getModuleAvatarIcon(RAFEntityName.TenantSettings), HasChildren: false },
    );
    return allLocalAdminMenuItems;
};

export function GetDefaultSystemSettingsNavigationMenuItems() {
    const allLocalSystemSettingsMenuItems: MenuRow[] = [];

    allLocalSystemSettingsMenuItems.push(
        { Name: RAFEntityName.User, Entity: RAFEntityName.User, Text: "Users", Url: "/user/list", Icon: getModuleAvatarIcon(RAFEntityName.User), HasChildren: false },
        { Name: RAFEntityName.Category, Entity: RAFEntityName.Category, Text: "Category", Url: "/Category/list", Icon: getModuleAvatarIcon(RAFEntityName.Category), HasChildren: true },
        { Name: RAFEntityName.Template, Entity: RAFEntityName.Template, Text: "Message Templates", Url: "/template/list", Icon: getModuleAvatarIcon(RAFEntityName.UserSignature), HasChildren: false },
        { Name: RAFEntityName.DataList, Entity: RAFEntityName.DataList, Text: "DataList", Url: "/DataList/List", "Icon": getModuleAvatarIcon(RAFEntityName.DataList), HasChildren: false },
        { Name: RAFEntityName.FormLibraryStatic, Text: "Form Library", Url: "/formLibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.FormLibrary), Entity: RAFEntityName.FormLibraryStatic, HasChildren: true },
        //{ Name: RAFEntityName.ProcessLibrary, Text: "Process Library", Url: "/processlibrary/list", "Icon": getModuleAvatarIcon(RAFEntityName.ProcessLibrary), Entity: RAFEntityName.FormLibrary, HasChildren: false },
        { Name: RAFEntityName.BusinessProcessTemplate, Text: "Process Catalog", Url: "/formflow/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate), Entity: RAFEntityName.BusinessProcessTemplate, HasChildren: true },
        { Name: RAFEntityName.BusinessProcess, Text: "Workflows", Url: "/processmonitor/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcess), Entity: RAFEntityName.BusinessProcess, HasChildren: true },
    );

    if (msalInstance.currentTenantName === RAFTenantName.CareESIO || isCurrentTenantCareESIOPartner() === true) {
        allLocalSystemSettingsMenuItems.push(
            { Name: "NDISPriceGuide", Text: "NDIS Price Guide", Entity: CareEsioEntity.CareNDISPriceGuide.EntityName, Url: getListPageUrlByModuleName(CareEsioEntity.CareNDISPriceGuide.EntityName), "Icon": getModuleAvatarIcon(CareEsioEntity.CareNDISPriceGuide.EntityName), HasChildren: false },
        );
    }

    return allLocalSystemSettingsMenuItems;
};


export function GetDefaultUserSettingsNavigationMenuItems() {
    const allLocalUserMenuItems: MenuRow[] = [];

    allLocalUserMenuItems.push(
        { Name: RAFEntityName.User, Entity: RAFEntityName.User, Text: "My Profile", Url: "/myprofile", Icon: getModuleAvatarIcon(RAFEntityName.User), HasChildren: false, },
        { Name: RAFEntityName.Template, Entity: RAFEntityName.Template, Text: "My Signature", Url: "/MySignature", Icon: getModuleAvatarIcon(RAFEntityName.UserSignature), HasChildren: false, },
        { Name: 'Notifications', Entity: 'Notifications', Text: "Notifications", Url: "/MyNotifications", Icon: getModuleAvatarIcon('Notifications'), HasChildren: false, },
    );

    //let localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalUserMenuItems);

    return allLocalUserMenuItems;
};

export function GetDefaultContentLibraryMenuItems() {
    const allLocalContentLibraryMenuItems: MenuRow[] = [];

    allLocalContentLibraryMenuItems.push(
        //{ Name: "business_process_template", Text: "Process Library", Url: "/ContentTemplate/list", "Icon": "group-arrows-rotate" },
        //{ Name: RAFEntityName.BusinessProcessType, Text: "Content Type", Url: "/ContentType/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessType) },
        { Name: RAFEntityName.ContentLibrary, Text: "Document Repository", Url: "/documentlibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.ContentLibrary) },
        { Name: RAFEntityName.FormLibrary, Text: "Form Library", Url: "/formlibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.FormLibrary) },
        { Name: RAFEntityName.Template, Text: "Message Templates", Url: "/template/list", Icon: getModuleAvatarIcon(RAFEntityName.UserSignature) },
    );

    // let localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalContentLibraryMenuItems);

    return allLocalContentLibraryMenuItems;
};

// export function checkMenuItemsUserPermission(permissions, allLocalUserMenuItems) {
//     let localUserMenuItems: MenuRow[] = [];

//     if (isNotNullAndUndefined(localUserMenuItems)) {
//         allLocalUserMenuItems.forEach((item: MenuRow) => {
//             let permission = false;
//             let permissionConstants = ACPermissionConstants[item.Name + 'Read'];
//             if (isNotNullAndUndefined(permissions) && isNotNullAndUndefined(permissionConstants)) {
//                 permission = hasPermission(permissions, permissionConstants);
//             } else {
//                 permission = true;
//             }
//             if (permission === true) {
//                 localUserMenuItems.push(item);
//             }
//         });
//     }
//     return localUserMenuItems;
// }

const hasChildrenForModule = (moduleName: string) => {
    const hasChildren = true;
    if (moduleName === 'Payroll' ||
        moduleName === 'Billing' ||
        moduleName === 'SupportLog' ||
        moduleName === RAFEntityName.ServiceTransaction ||
        moduleName === RAFEntityName.ContentLibrary) {
        return false;
    }
    return hasChildren;
};

export function ConvertWorkspaceItemRowToMenuRow(workspaceItems: WorkspaceItemRow[]) {
    let leftMenuLocal: MenuRow[] = [];
    workspaceItems && workspaceItems.forEach((item/*, index*/) => {
        if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Weblink) {

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Entity: item.Entity, Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : "globe", itemType: RAFWorkspaceRelatedtoType.Weblink, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);

        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Category) {
            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Entity: item.Entity, Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate), itemType: RAFWorkspaceRelatedtoType.Category, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);
        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Module) {
            const hasChildren = hasChildrenForModule(item.Entity);
            let entityName = getURLNameByModuleName(item.Entity);

            // entityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
            entityName = isNotNullAndUndefined(entityName) ? entityName.toLowerCase() : null;

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                //Name: ConvertSystemName(item.Entity), Text: item.Title, Url: "/" + entityName + "/list/", Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleIcon(item.Entity), isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false
                UID: item.UID, Name: ConvertSystemName(item.Entity), Entity: item.Entity, Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), itemType: RAFWorkspaceRelatedtoType.Module, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID, HasChildren: false
            });
            leftMenuLocal.push(...menuSrc);

        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.View) {
            let entityName = getURLNameByModuleName(item.Entity, false);
            let url;
            if (isNotNullAndUndefined(entityName) && msalInstance.currentTenantName === RAFTenantName.skytrust) {
                url = `/${entityName.toLowerCase()}/list/${item.RelatedtoUID}`;
            } else {
                url = `/raf/list/${item.Entity}/${item.RelatedtoUID}`;
            }

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.RelatedtoUID, Name: ConvertSystemName(item.Entity), Entity: item.Entity, Text: item.Title, Url: url, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), itemType: RAFWorkspaceRelatedtoType.View, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);

        } else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Form) {
            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Entity: item.Entity, Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);
        }
    });
    return leftMenuLocal;
}

export const getURLNameByModuleName = (moduleName: string, getDefaultValue?: boolean) => {
    let entityName = getDefaultValue === false ? null : moduleName;
    if (moduleName === RAFEntityName.Account) {
        entityName = "company";
    } else if (moduleName === RAFEntityName.ContentLibrary) {
        entityName = "document";//content_library
    } else if (moduleName === RAFEntityName.BusinessProcessTemplate) {
        //entityName = "processtemplate";
        entityName = "contentlibrary";
    } else if (moduleName === RAFEntityName.BusinessProcess) {
        entityName = "process";
    } else if (moduleName === RAFEntityName.BusinessProcessType) {
        //entityName = "processTemplateType";
        entityName = "category";
    } else if (moduleName === RAFEntityName.Category) {
        //entityName = "processTemplateType";
        entityName = "category";
    } else if (moduleName === RAFEntityName.Contact) {
        entityName = "contact";
    }
    else if (moduleName === RAFEntityName.Deal) {
        entityName = "deal";
    }
    else if (moduleName === RAFEntityName.Ticket) {
        entityName = "ticket";
    }
    else if (moduleName === RAFEntityName.FormLibrary) {
        entityName = "formlibrary";
    }
    else if (moduleName === RAFEntityName.Complaint) {
        entityName = "complaint";
    }
    return entityName;
};

// export const getModuleAvatarClass = (moduleName) => {
//     let avatarClass = '';

//     if (moduleName === RAFEntityName.Contact) {
//         avatarClass = "contact-avatar";
//     }
//     else if (moduleName === RAFEntityName.Account) {
//         avatarClass = "company-avatar";
//     }
//     else if (moduleName === RAFEntityName.Deal) {
//         avatarClass = "deal-avatar";
//     }
//     else if (moduleName === RAFEntityName.Ticket) {
//         avatarClass = "ticket-avatar";
//     }
//     else if (moduleName === RAFEntityName.Email) {
//         avatarClass = "email-avatar";
//     }
//     else if (moduleName === RAFEntityName.Task) {
//         avatarClass = "task-avatar";
//     }
//     else if (moduleName === RAFEntityName.User) {
//         avatarClass = "user-avatar";
//     }
//     else if (moduleName === RAFEntityName.Team) {
//         avatarClass = "team-avatar";
//     }
//     else if (moduleName === RAFEntityName.UserSignature) {
//         avatarClass = "usersignature-avatar";
//     }
//     else if (moduleName === RAFEntityName.Contract) {
//         avatarClass = "contract-avatar";
//     }
//     else if (moduleName === RAFEntityName.BusinessProcess) {
//         avatarClass = "bp-avatar";
//     }
//     else if (moduleName === RAFEntityName.BusinessProcessTemplate) {
//         avatarClass = "bp-avatar";
//     }
//     else if (moduleName === RAFEntityName.ContentLibrary) {
//         avatarClass = "document-avatar";
//     }
//     else if (moduleName === RAFEntityName.FormLibrary) {
//         avatarClass = "form-library-avatar";
//     }
//     else if (moduleName === RAFEntityName.Template) {
//         avatarClass = "template-avatar";
//     }
//     else if (moduleName === 'module') {
//         avatarClass = "module-avatar";
//     }
//     else if (moduleName === 'workflow') {
//         avatarClass = "workflow-manager-avatar";
//     }
//     else if (moduleName === RAFEntityName.View) {
//         avatarClass = "view-avatar";
//     }
//     else {
//         avatarClass = `${moduleName}-avatar default-avatar`;
//     }
//     return avatarClass;
// };

// export const getModuleAvatarIcon = (moduleName) => {
//     let avatarIcon = '';
//     if (moduleName === 'home') {
//         avatarIcon = "home";
//     }
//     else if (moduleName === RAFEntityName.Task) {
//         avatarIcon = "bars-progress";
//     }
//     else if (moduleName === RAFEntityName.Contact) {
//         avatarIcon = "address-card";
//     }
//     else if (moduleName === RAFEntityName.Account) {
//         avatarIcon = "building";
//     }
//     else if (moduleName === RAFEntityName.Deal) {
//         avatarIcon = "circle-dollar-to-slot";
//     }
//     else if (moduleName === RAFEntityName.Ticket) {
//         avatarIcon = "ticket";
//     }
//     else if (moduleName === RAFEntityName.ContentLibrary) {
//         avatarIcon = "file-alt";
//     }
//     else if (moduleName === RAFEntityName.Email) {
//         avatarIcon = "envelope";
//     }
//     else if (moduleName === RAFEntityName.BusinessProcessTemplate || moduleName === RAFEntityName.BusinessProcess) {
//         avatarIcon = "group-arrows-rotate";
//     }
//     // else if (moduleName === RAFEntityName.BusinessProcessType) {
//     //     avatarIcon = "envelope";
//     // }
//     else if (moduleName === RAFEntityName.BusinessProcessType) {
//         avatarIcon = "group-arrows-rotate";
//     }
//     else if (moduleName === RAFEntityName.Category) {
//         avatarIcon = "group-arrows-rotate";
//     }
//     else if (moduleName === RAFEntityName.FormLibrary) {
//         avatarIcon = "file-invoice";
//     }
//     else if (moduleName === RAFEntityName.UserSignature) {
//         avatarIcon = "file-signature";
//     }
//     else if (moduleName === RAFEntityName.User) {
//         avatarIcon = "user";
//     }
//     else if (moduleName === RAFEntityName.Team) {
//         avatarIcon = "users";
//     }
//     else if (moduleName === RAFEntityName.Role) {
//         avatarIcon = "list-ol";
//     }
//     else if (moduleName === RAFEntityName.Portal) {
//         avatarIcon = "briefcase";
//     }
//     else if (moduleName === RAFEntityName.View) {
//         avatarIcon = "clipboard-list";
//     }
//     else if (moduleName === RAFEntityName.Complaint) {
//         avatarIcon = "fa fa-exclamation-triangle";
//     }
//     else if (moduleName === RAFEntityName.Note || moduleName === RAFEntityName.Activity) {
//         avatarIcon = "Comments";
//     }
//     else if (moduleName === RAFEntityName.VersionHistory) {
//         avatarIcon = "history";
//     }
//     else if (moduleName === RAFEntityName.Document) {
//         avatarIcon = "file-alt";
//     }
//     else if (moduleName === RAFEntityName.DataList) {
//         avatarIcon = "list-check";
//     }
//     else if (moduleName === CareEsioEntity.CareNDISPriceGuide.EntityName) {
//         avatarIcon = "money-bill-1-wave";
//     }
//     else if (moduleName === CareEsioEntity.CarePriceList.EntityName) {
//         avatarIcon = "hand-holding-dollar";
//     }
//     else {
//         // avatarIcon = "file";
//     }
//     return avatarIcon;
// };

export const getEntityCollectionNameByModuleName = (moduleName: string) => {
    let collectionName;
    if (!IsNullOrWhiteSpace(moduleName)) {
        const simpleEntityStorage = isNotNullAndUndefined(moduleName) ?
            getSessionStorage(StorageKey.entity_modulename + moduleName, true) : null;
        let entityRow: EntityRow = isNotNullAndUndefined(simpleEntityStorage) &&
            !IsNullOrWhiteSpace(simpleEntityStorage) &&
            isNotNullAndUndefined(JSON.parse(simpleEntityStorage)) ? JSON.parse(simpleEntityStorage) : null;
        if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
            collectionName = entityRow.CollectionName;
        }
    }

    return collectionName;
};

export const getCollectionNameByModuleName = (moduleName: string, defaultValue?: string) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
        let entityCollectionName = getEntityCollectionNameByModuleName(moduleName);

        if (!IsNullOrWhiteSpace(defaultValue)) {
            return defaultValue;
        } else if (!IsNullOrWhiteSpace(entityCollectionName)) {
            return entityCollectionName;
        } else {
            let collectionName = ConvertCollectionName(getDisplayNameByModuleName(moduleName));
            if (moduleName === 'home') {
                collectionName = "Home";
            }
            else if (moduleName === RAFEntityName.Task) {
                collectionName = "Tasks";
            }
            else if (moduleName === RAFEntityName.Contact) {
                collectionName = "Contacts";
            }
            else if (moduleName === RAFEntityName.Account) {
                collectionName = "Companies";
            }
            else if (moduleName === RAFEntityName.Deal) {
                collectionName = "Deals";
            }
            else if (moduleName === RAFEntityName.Ticket) {
                collectionName = "Tickets";
            }
            else if (moduleName === RAFEntityName.ContentLibrary) {
                //displayName = "Content Library";
                collectionName = "Documents";
                //collectionName = "Company Documents";
            }
            else if (moduleName === RAFEntityName.Email) {
                collectionName = "Emails";
            }
            else if (moduleName === RAFEntityName.BusinessProcessTemplate || moduleName === RAFEntityName.BusinessProcess) {
                // displayName = "Business Process";
                collectionName = "Process Library";
            }
            else if (moduleName === RAFEntityName.BusinessProcessType) {
                collectionName = "Content Types";
            }
            else if (moduleName === RAFEntityName.FormLibrary) {
                collectionName = "Form Library";
            }
            else if (moduleName === RAFEntityName.UserSignature) {
                collectionName = "User Signatures";
            }
            else if (moduleName === RAFEntityName.User) {
                collectionName = "Users";
            }
            else if (moduleName === RAFEntityName.Team) {
                collectionName = "Team";
            }
            else if (moduleName === RAFEntityName.Role) {
                collectionName = "Role";
            }
            else if (moduleName === RAFEntityName.Portal) {
                collectionName = "Portal";
            }
            else if (moduleName === 'view') {
                collectionName = "Views";
            }
            else if (moduleName === RAFEntityName.Complaint) {
                collectionName = "Complaint";
            }
            else if (moduleName === RAFEntityName.Portal) {
                collectionName = "Portals";
            }
            else if (moduleName === RAFEntityName.Portal) {
                collectionName = "Portals";
            }
            else if (moduleName === RAFEntityName.Activity) {
                collectionName = "Notes";
            }

            return collectionName;
        }
    } else {
        return defaultValue;
    }
};
export const getRelatedSectionCollectionNameByModuleName = (moduleName: string) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
        let collectionName = ConvertCollectionName(getDisplayNameByModuleName(moduleName));
        if (moduleName === RAFEntityName.Task) {
            collectionName = "Task";
        }
        else if (moduleName === RAFEntityName.Contact) {
            collectionName = "Linked Contacts";
        }
        return collectionName;
    } else {
        return;
    }
};

export const getEntityDisplayNameByModuleName = (moduleName: string) => {
    let displayName;
    if (!IsNullOrWhiteSpace(moduleName)) {
        const simpleEntityStorage = isNotNullAndUndefined(moduleName) ?
            getSessionStorage(StorageKey.entity_modulename + moduleName, true) : null;
        let entityRow: EntityRow = isNotNullAndUndefined(simpleEntityStorage) &&
            !IsNullOrWhiteSpace(simpleEntityStorage) &&
            isNotNullAndUndefined(JSON.parse(simpleEntityStorage)) ? JSON.parse(simpleEntityStorage) : null;
        if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
            displayName = entityRow.DisplayName;
        }
    }

    return displayName;
};

export const getDisplayNameByModuleName = (moduleName: string, defaultValue?: string) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
        let entityDisplayName = getEntityDisplayNameByModuleName(moduleName);
        if (!IsNullOrWhiteSpace(defaultValue)) {
            return defaultValue;
        } else if (!IsNullOrWhiteSpace(entityDisplayName)) {
            return entityDisplayName;
        } else {
            let displayName = ConvertToPascal(moduleName);
            if (moduleName === 'home') {
                displayName = "Home";
            }
            else if (moduleName === RAFEntityName.Task) {
                displayName = "Task";
            }
            else if (moduleName === RAFEntityName.Contact) {
                displayName = "Contact";
            }
            else if (moduleName === RAFEntityName.Account) {
                displayName = "Company";
            }
            else if (moduleName === RAFEntityName.Deal) {
                displayName = "Deal";
            }
            else if (moduleName === RAFEntityName.Ticket) {
                displayName = "Ticket";
            }
            else if (moduleName === RAFEntityName.ContentLibrary) {
                //displayName = "Content Library";
                displayName = "Document";
            }
            else if (moduleName === RAFEntityName.Email) {
                displayName = "Email";
            }
            else if (moduleName === RAFEntityName.BusinessProcessTemplate) {
                // displayName = "Business Process";
                //displayName = "Content Library";
                displayName = "Process Library";
            }
            else if (moduleName === RAFEntityName.BusinessProcess) {
                // displayName = "Business Process";
                displayName = "Process";
            }
            else if (moduleName === RAFEntityName.BusinessProcessType) {
                displayName = "Category";
                // displayName = "Content Type";
            }
            else if (moduleName === RAFEntityName.Category) {
                displayName = "Category";
            }
            else if (moduleName === RAFEntityName.FormLibrary) {
                displayName = "Form Library";
            }
            else if (moduleName === RAFEntityName.UserSignature) {
                displayName = "User Signature";
            }
            else if (moduleName === RAFEntityName.User) {
                displayName = "User";
            }
            else if (moduleName === RAFEntityName.Team) {
                displayName = "Team";
            }
            else if (moduleName === RAFEntityName.Role) {
                displayName = "Role";
            }
            else if (moduleName === RAFEntityName.Portal) {
                displayName = "Portal";
            }
            else if (moduleName === RAFEntityName.View) {
                displayName = "View";
            }
            else if (moduleName === RAFEntityName.Complaint) {
                displayName = "Complaint";
            }

            return displayName;
        }
    } else {
        return defaultValue;
    }
};

export function getDefaultFieldNameByModule(moduleName: string) {
    return new Promise<string>(async (resolve/*, reject*/) => {
        let fieldsList: QueryAttributeJM[] = await getFields(moduleName);
        let defaultField = isNotNullAndUndefined(fieldsList) ? fieldsList.find(x => x.IsDefault === true) : null;
        const propertyName = isNotNullAndUndefined(defaultField) ? defaultField.PropertyName : null;
        resolve(propertyName);
    });
    // let retVal;
    // getFields(moduleName).then(fieldsList => {
    //     let defaultField = fieldsList && fieldsList.find(x => x.IsDefault === true);
    //     retVal = defaultField.PropertyName;
    //     //return retVal;
    // });

    // // switch (moduleName) {
    // //     case RAFEntityName.Account:
    // //         retVal = "AccountName";
    // //         break;
    // //     case RAFEntityName.Contact:
    // //         retVal = "FirstName";
    // //         break;
    // //     case RAFEntityName.Deal:
    // //         retVal = "DealName";
    // //         break;
    // //     case RAFEntityName.Ticket:
    // //         retVal = "Subject";
    // //         break;
    // //     case RAFEntityName.RecordCategory:
    // //         retVal = "Title";
    // //         break;
    // //     case RAFEntityName.City:
    // //         retVal = "CityName";
    // //         break;
    // //     case RAFEntityName.State:
    // //         retVal = "StateName";
    // //         break;
    // //     case RAFEntityName.Country:
    // //         retVal = "CountryName";
    // //         break;
    // //     default:
    // //         break;
    // // }
    // return retVal;
}

export const removeFromFocusList = (relatedToUID: string, focusUserUID?: string) => {
    let focusServiceRequest: FocusServiceRequest = new FocusServiceRequest();
    focusServiceRequest.RelatedToUID = relatedToUID;
    if (isNotNullAndUndefined(focusUserUID)) {
        focusServiceRequest.FocusUserUID = focusUserUID;
    }

    return new Promise<boolean>((resolve/*, reject*/) => {
        if (isNotNullAndUndefined(relatedToUID)) {
            return repositoryActions.postDataAndGetResponse(
                'UserFocus/Remove',
                focusServiceRequest,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true)
                        resolve(true);
                    else
                        resolve(false);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
        } else {
            resolve(false);
        }
    });
};


export function onLookupLinkClicked(moduleName: string, relatedUID: string, isDynamic?: boolean, navigate?: any) {
    if (isNotNullAndUndefined(moduleName) && isNotNullAndUndefined(relatedUID)) {
        let url;
        //updated the url by hari
        if (moduleName === "Process") {
            url = "/Process/Update/" + relatedUID;
        }
        else if (moduleName === RAFEntityName.Contact) {
            url = "/Contact/View/" + relatedUID;
        }
        else if (moduleName === RAFEntityName.Account) {
            url = "/Company/View/" + relatedUID;
        }
        else if (moduleName === RAFEntityName.Ticket) {
            url = "/Ticket/View/" + relatedUID;
        }
        else {
            if (isDynamic === true) {
                url = "/RAF/View/" + moduleName + "/" + relatedUID;
            } else {
                const entity = getURLNameByModuleName(moduleName, false); //temp fix only -> dynamic details page not fully implemented 
                if (isNotNullAndUndefined(entity) && msalInstance.currentTenantName === RAFTenantName.skytrust) {
                    url = '/' + entity + "/View/" + relatedUID;
                } else {
                    url = "/RAF/View/" + moduleName + "/" + relatedUID;
                }
            }
        }

        if (isNotNullAndUndefined(navigate)) {
            navigate(url);
        } else {
            window.open(url);
        }
    }
}

export const getUpdateComponet = (moduleName: string) => {
    let retVal: React.ReactNode | any = null;
    if (!IsNullOrWhiteSpace(moduleName)) {
        switch (moduleName.toLowerCase()) {
            case RAFEntityName.Contact:
                retVal = UpdateContact;
                break;
            case RAFEntityName.Account:
                retVal = UpdateAccount;
                break;
            case RAFEntityName.Deal:
                retVal = UpdateDeal;
                break;
            case RAFEntityName.Ticket:
                retVal = UpdateTicket;
                break;
            case RAFEntityName.StandardizedLibrary:
                retVal = RAFManageStandardizedLibrary;
                break;
            case RAFEntityName.User:
                retVal = ManageUser;
                break;
            case RAFEntityName.Team:
                retVal = ManageUserGroup;
                break;
            case RAFEntityName.Employee:
                retVal = ManageEmployee;
                break;
            case CareEsioEntity.ChecklistTemplateItem.EntityName:
                retVal = ManageChecklistTemplateItem;
                break;
            case CareEsioEntity.ChecklistTemplate.EntityName:
                retVal = ManageChecklistTemplate;
                break;
            case CareEsioEntity.CarePayItem.EntityName:
                retVal = ManagePayItem;
                break;
            case CareEsioEntity.CareServiceContract.EntityName:
                retVal = ManageCareServiceContract;
                break;
            default:
                retVal = RAFEdit;
                break;
        }
    }
    return retVal;
};

export const getCreateComponent = (moduleName: string) => {
    let retVal: React.ReactNode | any = null;
    if (!IsNullOrWhiteSpace(moduleName)) {
        switch (moduleName.toLowerCase()) {
            case RAFEntityName.Contact:
                retVal = CreateContact;
                break;
            case RAFEntityName.Account:
                retVal = CreateAccount;
                break;
            case RAFEntityName.Deal:
                retVal = CreateDeal;
                break;
            case RAFEntityName.Ticket:
                retVal = CreateTicket;
                break;
            case RAFEntityName.City:
                retVal = CreateCity;
                break;
            case RAFEntityName.State:
                retVal = CreateState;
                break;
            case RAFEntityName.Country:
                retVal = CreateCountry;
                break;
            case RAFEntityName.User:
                retVal = ManageUser;
                break;
            case RAFEntityName.Team:
                retVal = ManageUserGroup;
                break;
            case RAFEntityName.StandardizedLibrary:
                retVal = RAFManageStandardizedLibrary;
                break;
            case CareEsioEntity.ChecklistTemplateItem.EntityName:
                retVal = ManageChecklistTemplateItem;
                break;
            case CareEsioEntity.ChecklistTemplate.EntityName:
                retVal = CreateChecklistTemplate;
                break;
            case RAFEntityName.Employee:
                retVal = ManageEmployee;
                break;
            case CareEsioEntity.CarePayItem.EntityName:
                retVal = ManagePayItem;
                break;
            case CareEsioEntity.CareServiceContract.EntityName:
                retVal = ManageCareServiceContract;
                break;
            default:
                retVal = RAFCreate;
                break;
        }
    }
    return retVal;

};

export const getFocusListByRelatedUIDFocusType = (
    relatedToType: string,
    relatedToUID: string,
    focusUserType: string[],
    focusUserUID: string[],

) => {
    return new Promise<FocusRow[]>((resolve/*, reject*/) => {
        const listRequest: ListServiceRequest = new ListServiceRequest();

        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "or";
        customFilter.Rules = [];

        if (isNotNullAndUndefined(focusUserType) &&
            isNotNullAndUndefined(focusUserUID) &&
            focusUserType.length === focusUserUID.length
        ) {
            let i = 0;

            for (i = 0; i < focusUserType.length; i++) {
                let relatedFilter: RAFCustomFilter = {};
                relatedFilter.Condition = "and";
                relatedFilter.Rules = [];

                if (isNotNullAndUndefined(focusUserType[i]) &&
                    isNotNullAndUndefined(focusUserUID[i])) {
                    if (isNotNullAndUndefined(relatedToUID)) {
                        let filter: RAFCustomFilter = {};
                        let filterVal: string[] = [];
                        filterVal.push(relatedToUID);
                        filter.Operator = RAFCustomOperator.Equal;
                        filter.Value = filterVal;
                        filter.Field = propertyOf<FocusRow>("RelatedToUID");
                        relatedFilter.Rules.push(filter);
                    }


                    if (isNotNullAndUndefined(relatedToType)) {
                        let filter2: RAFCustomFilter = {};
                        let filterVal2: string[] = [];
                        filterVal2.push(relatedToType);
                        filter2.Operator = RAFCustomOperator.Equal;
                        filter2.Value = filterVal2;
                        filter2.Field = propertyOf<FocusRow>("RelatedToType");
                        relatedFilter.Rules.push(filter2);
                    }

                    let filter1: RAFCustomFilter = {};
                    let filterVal1: string[] = [];
                    filterVal1.push(focusUserType[i]);
                    filter1.Operator = RAFCustomOperator.Equal;
                    filter1.Value = filterVal1;
                    filter1.Field = propertyOf<FocusRow>("FocusUserType");
                    relatedFilter.Rules.push(filter1);

                    let filter2: RAFCustomFilter = {};
                    let filterVal2: string[] = [];
                    filterVal2.push(focusUserUID[i]);
                    filter2.Operator = RAFCustomOperator.Equal;
                    filter2.Value = filterVal2;
                    filter2.Field = propertyOf<FocusRow>("FocusUserUID");
                    relatedFilter.Rules.push(filter2);

                }

                customFilter.Rules.push(relatedFilter);
            }
        }

        listRequest.CustomFilter = customFilter;
        listRequest.Skip = 0;
        listRequest.Take = 0;

        let sort: RAFSort = { field: propertyOf<FocusRow>("ModifiedDate"), order: 'descending' };
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
            sortQuery.push(sort.order === 'descending' ? `${sort.field} desc` : sort.field);
        }
        listRequest.Sort = sortQuery;

        repositoryActions.postDataAndGetResponse('UserFocus/List', listRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch(error => error);
    });
};

export const getFocusListByRelatedUID = (relatedToUID: string) => {
    return new Promise<boolean>((resolve/*, reject*/) => {
        if (isNotNullAndUndefined(relatedToUID)) {
            let focusServiceRequest: FocusServiceRequest = new FocusServiceRequest();
            focusServiceRequest.RelatedToUID = relatedToUID;

            return repositoryActions.postDataAndGetResponse(
                'UserFocus/RetrieveByRelatedUID',
                focusServiceRequest,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
        } else {
            resolve(false);
        }
    });
};

export function getListPageMoreMenuItems(
    currentView: RAFTreeViewMenu,
    isSMItem?: boolean,
    hideRelatedMenuOption?: boolean,
    showViewItems?: boolean,
    moduleName?: string,
    rolePermissionRow?: RolePermissionRow,
): MenuItemModel[] {
    if (hideRelatedMenuOption !== true && isNotNullAndUndefined(currentView) && isNotNullAndUndefined(currentView.DisplayName)) {
        let category = currentView.Category;

        let menuItems: MenuItemModel[] = [];

        if (IsSuperAdmin() === true) {
            if (
                hasPermission(rolePermissionRow, `${moduleName}::Customize`)
            ) {
                menuItems.push({
                    id: "Customize",
                    text: "Customize",
                    iconCss: "fas fa-money-check-pen",
                });
            }
            menuItems.push({
                id: "Share",
                text: "Share",
                iconCss: "fas fa-share-alt",
            });
        }

        if (category !== RAFReportType.Dashboard && category !== RAFReportType.Analytic) {
            if (IsSuperAdmin() && hasPermission(rolePermissionRow, `${moduleName}::Import`)) {
                menuItems.push({
                    id: "Import",
                    text: "Import",
                    iconCss: "fas fa-upload",
                });
            }
            if (hasPermission(rolePermissionRow, `${moduleName}::Export`)) {
                menuItems.push(
                    {
                        text: "Export",
                        iconCss: "fas fa-download",
                        items: [
                            {
                                id: "Pdf",
                                text: "Pdf",
                                iconCss: "fas fa-file-pdf",
                            },
                            {
                                id: "Csv",
                                text: "Csv",
                                iconCss: "fas fa-file-csv",
                            },
                            {
                                id: "Excel",
                                text: "Excel",
                                iconCss: "fas fa-file-excel",
                            },
                        ],
                    }
                );
            }
            if (IsSuperAdmin() === true) {
                menuItems.push(
                    {
                        id: "ShowOffline",
                        text: "Show Offline",
                        iconCss: "fas fa-money-check-pen",
                    }
                );
            }
        }

        //if (category !== RAFReportType.Dashboard && isNotNullAndUndefined(currentView.UID)) {
        // if (category === RAFReportType.Analytic && isNotNullAndUndefined(currentView.UID)) {

        //     menuItems.push({
        //         id: "AddToDashboard",
        //         text: "Add to Dashboard",
        //         iconCss: "fa fa-th-large",
        //     });
        // }

        if (isSMItem === true && category === RAFReportType.Dashboard && isNotNullAndUndefined(currentView.UID)) {
            menuItems.push({
                id: "EditDashboard",
                text: "Edit Dashboard",
                iconCss: "fa fa-th-large",
            });
        }
        if (showViewItems) {
            menuItems.push(
                {
                    id: RAFButtonConstant.ManageViews.Id,
                    text: RAFButtonConstant.ManageViews.DisplayName,
                    iconCss: RAFButtonConstant.ManageViews.IconCss,
                    items: [
                        {
                            id: RAFButtonConstant.ManageTableViews.Id,
                            text: RAFButtonConstant.ManageTableViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageTableViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManageChartViews.Id,
                            text: RAFButtonConstant.ManageChartViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageChartViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManageGroupViews.Id,
                            text: RAFButtonConstant.ManageGroupViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageGroupViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManagePivotViews.Id,
                            text: RAFButtonConstant.ManagePivotViews.DisplayName,
                            iconCss: RAFButtonConstant.ManagePivotViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManageReportTableViews.Id,
                            text: RAFButtonConstant.ManageReportTableViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageReportTableViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManageMetricViews.Id,
                            text: RAFButtonConstant.ManageMetricViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageMetricViews.IconCss,
                        }
                        , {
                            id: RAFButtonConstant.ManageDashboardViews.Id,
                            text: RAFButtonConstant.ManageDashboardViews.DisplayName,
                            iconCss: RAFButtonConstant.ManageDashboardViews.IconCss,
                        }
                    ],
                }
                ,
            );
        }
        return menuItems;
    } else {
        if (window.innerWidth > 576) {
            let menuItems: MenuItemModel[] = [];

            if (IsSuperAdmin() === true) {
                if (hasPermission(rolePermissionRow, `${moduleName}::Customize`)) {
                    menuItems.push(
                        {
                            id: "Customize",
                            text: "Customize",
                            iconCss: "fas fa-money-check-pen",
                        },
                    );
                }

                menuItems.push(
                    {
                        id: "ShowOffline",
                        text: "Show Offline",
                        iconCss: "fas fa-money-check-pen",
                    });
            }
            if (showViewItems) {
                menuItems.push(
                    {
                        id: RAFButtonConstant.ManageViews.Id,
                        text: RAFButtonConstant.ManageViews.DisplayName,
                        iconCss: RAFButtonConstant.ManageViews.IconCss,
                        items: [
                            {
                                id: RAFButtonConstant.ManageTableViews.Id,
                                text: RAFButtonConstant.ManageTableViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageTableViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManageChartViews.Id,
                                text: RAFButtonConstant.ManageChartViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageChartViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManageGroupViews.Id,
                                text: RAFButtonConstant.ManageGroupViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageGroupViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManagePivotViews.Id,
                                text: RAFButtonConstant.ManagePivotViews.DisplayName,
                                iconCss: RAFButtonConstant.ManagePivotViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManageReportTableViews.Id,
                                text: RAFButtonConstant.ManageReportTableViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageReportTableViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManageMetricViews.Id,
                                text: RAFButtonConstant.ManageMetricViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageMetricViews.IconCss,
                            }
                            , {
                                id: RAFButtonConstant.ManageDashboardViews.Id,
                                text: RAFButtonConstant.ManageDashboardViews.DisplayName,
                                iconCss: RAFButtonConstant.ManageDashboardViews.IconCss,
                            }
                        ],
                    }
                    ,
                );
            }

            return menuItems;
        } else {
            return [];
        }
    }
}

export function showHideSideMenu2ToggleBtn(expandBtn: boolean, collapseBtn: boolean) {
    const btnOpenSidemenu2 = document.getElementById("btn_open_sidemenu2");
    const btnCloseSidemenu2 = document.getElementById("btn_close_sidemenu2");
    if (isNotNullAndUndefined(btnOpenSidemenu2)) {
        if (expandBtn === true) {
            btnOpenSidemenu2.classList.remove('hidden');
        }
        else {
            btnOpenSidemenu2.classList.add('hidden');
        }
    }
    if (isNotNullAndUndefined(btnCloseSidemenu2)) {
        if (collapseBtn === true) {

            btnOpenSidemenu2.classList.remove('hidden');
        }
        else {
            btnOpenSidemenu2.classList.add('hidden');
        }
    }
}

export function onToggleSideMenu(mode?: `open` | `close`) {
    const navigationElement = document.getElementById("listSidebar");
    const navigationElement2 = document.querySelector("#listSidebar_right");
    const eSidebarLeft = document.querySelector("#eSidebarLeft");
    const listSidebarRight = document.querySelector("#listSidebar_right");
    const sidebarContent = document.getElementById("sidebar-content");
    const windowWidth = window.innerWidth;
    let ShowSideMenu2 = getSessionStorage(
        StorageKey.ShowSideMenu2, true
    );
    const btnOpenSidemenu2 = document.getElementById("btn_open_sidemenu2");
    const btnCloseSidemenu2 = document.getElementById("btn_close_sidemenu2");
    let navigationElementWidth;
    if (ShowSideMenu2 === "true") {
        navigationElementWidth = (isNotNullAndUndefined(eSidebarLeft) && eSidebarLeft.clientWidth) + (isNotNullAndUndefined(listSidebarRight) && listSidebarRight.clientWidth);
        if (isNotNullAndUndefined(btnOpenSidemenu2)) {
            btnOpenSidemenu2.classList.add('hidden');
        }
        if (isNotNullAndUndefined(btnCloseSidemenu2)) {
            btnCloseSidemenu2.classList.remove('hidden');
        }
    }
    else {
        navigationElementWidth = (isNotNullAndUndefined(eSidebarLeft) && eSidebarLeft.clientWidth);
        if (isNotNullAndUndefined(btnOpenSidemenu2)) {
            btnOpenSidemenu2.classList.remove('hidden');
        }
        if (isNotNullAndUndefined(btnCloseSidemenu2)) {
            btnCloseSidemenu2.classList.add('hidden');
        }
    }

    if (isNotNullAndUndefined(navigationElement)) {
        switch (mode) {
            case ('open'):
                setTimeout(() => {
                    setSessionStorage(
                        StorageKey.ShowSideMenu,
                        true,
                        true
                    );
                    navigationElement.classList.remove("e-close");
                    navigationElement.classList.add("e-open");
                    navigationElement.style.width = `${navigationElementWidth}px`;
                    if (isNotNullAndUndefined(navigationElement2) && (ShowSideMenu2 === "true" || windowWidth < 767)) {
                        navigationElement2.classList.remove("e-close");
                        navigationElement2.classList.add("e-open");
                    }
                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = `${navigationElementWidth}px`;
                    }
                    const transparentBackdrop = document.querySelector(".transparentBackdrop");
                    if (transparentBackdrop !== null && windowWidth < 767) {
                        transparentBackdrop.classList.add("show");
                    }
                }, 100);
                break;
            case ('close'):
                setTimeout(() => {
                    setSessionStorage(
                        StorageKey.ShowSideMenu,
                        true,
                        false
                    );
                    navigationElement.classList.add("e-close");
                    navigationElement.classList.remove("e-open");
                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = "0px";
                    }
                    const transparentBackdrop = document.querySelector(".transparentBackdrop");
                    if (transparentBackdrop !== null && windowWidth < 767) {
                        transparentBackdrop.classList.remove("show");
                    }
                    if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                        btnOpenSidemenu2.classList.add('hidden');
                    }
                    if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                        btnCloseSidemenu2.classList.add('hidden');
                    }
                }, 100);
                break;
            default:
                if (navigationElement.classList.contains("e-close")) {
                    setTimeout(() => {
                        setSessionStorage(
                            StorageKey.ShowSideMenu,
                            true,
                            true
                        );
                        navigationElement.classList.remove("e-close");
                        navigationElement.classList.add("e-open");
                        navigationElement.style.width = `${navigationElementWidth}px`;
                        if (isNotNullAndUndefined(navigationElement2) && (ShowSideMenu2 === "true" || windowWidth < 767)) {
                            navigationElement2.classList.remove("e-close");
                            navigationElement2.classList.add("e-open");
                        }
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = `${navigationElementWidth}px`;
                        }
                        const transparentBackdrop = document.querySelector(".transparentBackdrop");
                        if (transparentBackdrop !== null && windowWidth < 767) {
                            transparentBackdrop.classList.add("show");
                        }
                    }, 100);
                }
                if (navigationElement.classList.contains("e-open")) {
                    setTimeout(() => {
                        setSessionStorage(
                            StorageKey.ShowSideMenu,
                            true,
                            false
                        );
                        navigationElement.classList.add("e-close");
                        navigationElement.classList.remove("e-open");
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = "0px";
                        }
                        const transparentBackdrop = document.querySelector(".transparentBackdrop");
                        if (transparentBackdrop !== null && windowWidth < 767) {
                            transparentBackdrop.classList.remove("show");
                        }
                        if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                            btnOpenSidemenu2.classList.add('hidden');
                        }
                        if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                            btnCloseSidemenu2.classList.add('hidden');
                        }
                    }, 100);
                }
                break;
        }
    }
}

export function onToggleSideMenu2(mode?: `open` | `close`) {
    const navigationLeftMenu = document.getElementById("listSidebar");
    const navigationElement = document.getElementById("listSidebar_right");
    //const eSidebarLeft = document.querySelector("#eSidebarLeft");
    //const listSidebarRight = document.querySelector("#listSidebar_right");
    const sidebarContent = document.getElementById("sidebar-content");
    const btnOpenSidemenu2 = document.getElementById("btn_open_sidemenu2");
    const btnCloseSidemenu2 = document.getElementById("btn_close_sidemenu2");
    const windowWidth = window.innerWidth;
    //let navigationElementWidth = (isNotNullAndUndefined(eSidebarLeft) && eSidebarLeft.clientWidth) + (isNotNullAndUndefined(listSidebarRight) && listSidebarRight.clientWidth);
    if (isNotNullAndUndefined(navigationElement)) {
        switch (mode) {
            case ('open'):
                navigationElement.classList.remove("hidden");
                setTimeout(() => {
                    navigationElement.classList.remove("e-close");
                    navigationElement.classList.add("e-open");
                    navigationLeftMenu.style.width = `${navigationElement.clientWidth + 65}px`;

                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = `${navigationElement.clientWidth + 65}px`;
                    }
                    // const transparentBackdrop = document.querySelector(".transparentBackdrop");
                    // if (transparentBackdrop !== null && windowWidth < 767) {
                    //     transparentBackdrop.classList.add("show");
                    // }
                    if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                        btnOpenSidemenu2.classList.add('hidden');
                    }
                    if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                        btnCloseSidemenu2.classList.remove('hidden');
                    }
                    setSessionStorage(
                        StorageKey.ShowSideMenu2,
                        true,
                        true
                    );
                }, 100);
                break;
            case ('close'):
                setTimeout(() => {
                    navigationElement.classList.add("e-close");
                    navigationElement.classList.remove("e-open");
                    navigationLeftMenu.style.width = 'auto';
                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = `${65}px`;
                    }
                    // const transparentBackdrop = document.querySelector(".transparentBackdrop");
                    // if (transparentBackdrop !== null && windowWidth < 767) {
                    //     transparentBackdrop.classList.remove("show");
                    // }
                    if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                        btnOpenSidemenu2.classList.remove('hidden');
                    }
                    if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                        btnCloseSidemenu2.classList.add('hidden');
                    }
                    setSessionStorage(
                        StorageKey.ShowSideMenu2,
                        true,
                        false
                    );
                }, 100);
                break;
            default:
                if (navigationElement.classList.contains("e-close")) {
                    navigationElement.classList.remove("hidden");
                    setTimeout(() => {
                        navigationElement.classList.remove("e-close");
                        navigationElement.classList.add("e-open");
                        navigationLeftMenu.style.width = `${navigationElement.clientWidth + 65}px`;
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = `${navigationElement.clientWidth + 65}px`;
                        }
                        // const transparentBackdrop = document.querySelector(".transparentBackdrop");
                        // if (transparentBackdrop !== null && windowWidth < 767) {
                        //     transparentBackdrop.classList.add("show");
                        // }
                        if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                            btnOpenSidemenu2.classList.remove('hidden');
                        }
                        if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                            btnCloseSidemenu2.classList.add('hidden');
                        }
                        setSessionStorage(
                            StorageKey.ShowSideMenu2,
                            true,
                            true
                        );
                    }, 100);
                }
                if (navigationElement.classList.contains("e-open")) {
                    setTimeout(() => {
                        navigationElement.classList.add("e-close");
                        navigationElement.classList.remove("e-open");
                        navigationLeftMenu.style.width = 'auto';
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = `${65}px`;
                        }
                        // const transparentBackdrop = document.querySelector(".transparentBackdrop");
                        // if (transparentBackdrop !== null && windowWidth < 767) {
                        //     transparentBackdrop.classList.remove("show");
                        // }
                        if (isNotNullAndUndefined(btnOpenSidemenu2)) {
                            btnOpenSidemenu2.classList.add('hidden');
                        }
                        if (isNotNullAndUndefined(btnCloseSidemenu2)) {
                            btnCloseSidemenu2.classList.remove('hidden');
                        }
                        setSessionStorage(
                            StorageKey.ShowSideMenu2,
                            true,
                            false
                        );
                    }, 100);
                }
                break;
        }
    }
}

export type GridPageContext = {
    viewMenuDataSource: RAFTreeViewMenu[], currentView: RAFTreeViewMenu, updateviewMenuDataSource: (viewId?: string) => void, onUpdateRelatedViews?: () => void, updateCurrentView?: (item: RAFTreeViewMenu) => void;
};

export const defaultMenuItems: MenuRow[] = [
    {
        UID: Guid.newGuid(),
        Name: "home",
        Text: "Home",
        // Url: "/home",
        Url: "/",
        Icon: getModuleAvatarIcon("home"),
    },
    {
        UID: Guid.newGuid(),
        Name: "inbox",
        Text: "Inbox",
        Url: "/Inbox",
        Icon: getModuleAvatarIcon("inbox"),
    },
    {
        UID: Guid.newGuid(),
        Name: "task_dashboard",
        Text: "Tasks",
        Url: "/taskdashboard",
        HasChildren: true,
        Entity: RAFEntityName.Task,
        itemType: RAFWorkspaceRelatedtoType.Module,
        Icon: getModuleAvatarIcon(RAFEntityName.Task),
    },
    // {
    //     UID: Guid.newGuid(),
    //     Name: "calendar_dashboard",
    //     Text: "Calendar",
    //     Url: "/CalendarDashboard",
    //     Icon: getModuleAvatarIcon(RAFEntityName.Task),
    // },
];
export const responsiveDefaultMenuItems: MenuRow[] = [
    {
        UID: Guid.newGuid(),
        Name: "task_dashboard",
        Text: "Tasks",
        Url: "/taskdashboard",
        HasChildren: true,
        Entity: RAFEntityName.Task,
        itemType: RAFWorkspaceRelatedtoType.Module,
        Icon: getModuleAvatarIcon(RAFEntityName.Task),
    },
    // {
    //     UID: Guid.newGuid(),
    //     Name: "calendar_dashboard",
    //     Text: "Calendar",
    //     Url: "/CalendarDashboard",
    //     Icon: getModuleAvatarIcon(RAFEntityName.Task),
    // },
];

export const getCurrentMenuCategory = () => {
    let currentMenuCategory = getSessionStorage(
        RMStorageKey.currentMenuCategory,
        false
    );
    // if (IsNullOrWhiteSpace(currentMenuCategory)) {
    const currentPath = window.location.pathname.toLowerCase();
    if (isNotNullAndUndefined(currentPath)) {
        const adminMenu: MenuRow[] = GetDefaultAdminNavigationMenuItems();
        const isURLExistInAdminMenu = adminMenu.some(x => IsNotNullOrWhiteSpace(x.Url) && x.Url === currentPath.toLowerCase());
        const userMenu: MenuRow[] = GetDefaultUserSettingsNavigationMenuItems();
        const isURLExistInUserMenu = userMenu.some(x => IsNotNullOrWhiteSpace(x.Url) && x.Url === currentPath.toLowerCase());
        const systemMenu: MenuRow[] = GetDefaultSystemSettingsNavigationMenuItems();
        const isURLExistInSystemMenu = systemMenu.some(x => IsNotNullOrWhiteSpace(x.Url) && x.Url === currentPath.toLowerCase());
        if (isURLExistInAdminMenu) {
            setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.AdminSettings);
            setSessionStorage(RMStorageKey.isAdmin, false, 'true');
            currentMenuCategory = MenuCategory.AdminSettings;
        } else if (isURLExistInUserMenu) {
            setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.UserSettings);
            setSessionStorage(RMStorageKey.isAdmin, false, 'false');
            currentMenuCategory = MenuCategory.UserSettings;
        } else if (isURLExistInSystemMenu) {
            if (ISESIOAppAdministrator()) {
                setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.SystemSettings);
                setSessionStorage(RMStorageKey.isAdmin, false, 'false');
                currentMenuCategory = MenuCategory.SystemSettings;
            } else {
                setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.Default);
                setSessionStorage(RMStorageKey.isAdmin, false, 'false');
                currentMenuCategory = MenuCategory.Default;
            }
        } else if (IsNullOrWhiteSpace(currentMenuCategory)) {
            setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.Default);
            setSessionStorage(RMStorageKey.isAdmin, false, 'false');
            currentMenuCategory = MenuCategory.Default;
        }
    }
    // };
    return currentMenuCategory;
};

export const getLeftMenuItems = (
    currentWorkspace: WorkspaceRow,
    workspaceItems: WorkspaceItemRow[]
) => {
    const currentMenuCategory = getCurrentMenuCategory();

    let leftMenuData: MenuRow[] = [];
    if (
        isNullOrUndefined(currentMenuCategory) ||
        currentMenuCategory === MenuCategory.Default ||
        currentMenuCategory === MenuCategory.ContentManagement
    ) {
        const mainMenu: MenuRow[] =
            isNotNullAndUndefined(currentWorkspace) &&
                isNotNullAndUndefined(currentWorkspace.PortalName)
                ? isNotEmptyArray(workspaceItems)
                    ? ConvertWorkspaceItemRowToMenuRow(workspaceItems)
                    : []
                : []; //[]; GetDefaultMainNavigationMenuItems()

        leftMenuData = defaultMenuItems.concat(mainMenu);
        // if (BrowserIsDevice) {

        //     leftMenuData = responsiveDefaultMenuItems.concat(mainMenu);
        // }
        //leftMenuData = defaultMenuItems.concat(GetDefaultMainNavigationMenuItems());
    } else if (currentMenuCategory === MenuCategory.AdminSettings) {
        let adminMenu: MenuRow[] = GetDefaultAdminNavigationMenuItems();
        isNotEmptyArray(adminMenu) && adminMenu.forEach(x => {
            // x.HasChildren = true;
            x.itemType = RAFWorkspaceRelatedtoType.Module;
        });
        leftMenuData = adminMenu;
    } else if (currentMenuCategory === MenuCategory.UserSettings) {
        let userMenu: MenuRow[] = GetDefaultUserSettingsNavigationMenuItems();
        isNotEmptyArray(userMenu) && userMenu.forEach(x => {
            //x.HasChildren = true;
            x.itemType = RAFWorkspaceRelatedtoType.Module;
        });
        leftMenuData = userMenu;
    } else if (currentMenuCategory === MenuCategory.SystemSettings && ISESIOAppAdministrator()) {
        let systemMenu: MenuRow[] = GetDefaultSystemSettingsNavigationMenuItems();
        isNotEmptyArray(systemMenu) && systemMenu.forEach(x => {
            //x.HasChildren = true;
            x.itemType = RAFWorkspaceRelatedtoType.Module;
        });
        if (IsAdminQueryString()) {

        }
        else {
            systemMenu = systemMenu.filter(x => x.Name !== RAFEntityName.BusinessProcessTemplate && x.Name !== RAFEntityName.BusinessProcess);
        }
        leftMenuData = systemMenu;
    }

    return leftMenuData;
};