import React, { Component, PropsWithChildren, ReactNode } from "react";
import { getURLNameByModuleName } from "../helpers/RAFMenuHelper";
import { NavigateParamsProps, withRouter } from "../../router";
import { getQueryAttribute } from "../helpers/AppHelper";
import { deepEqual, isNotNullAndUndefined } from "../helpers/utils";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFDataType } from "../models/Common/RAFDataType";
import "./NavigationStyle.scss";
import { RAFInputType } from "../../constants/Common/Constants";
import moment from "moment";
import { isValidReactNode } from "../Inputs/RFFUtils";

interface IProps {
  value?: Object;
  valueUID?: string;
  label?: string;
  iconName?: string;
  moduleName?: string;
  fieldName?: string;
  isColorOption?: boolean;
  showValueIcon?: boolean;
  emptyString?: string;
  mode?: "discView" | "squareView" | "iconView" | "outlineView";
  customContent?: React.ReactFragment | ReactNode;
  customSectionOuter?: React.ReactFragment | ReactNode;
  customSectionOuterClass?: string;
  cssClass?: string;
  format?: RAFInputType;
}

interface IState {
  colorCode: string;
  displayName: string;
  navigationEnabled: boolean;
  relatedEntity: string;
}

class RAFSummaryCount extends Component<
  PropsWithChildren<IProps> & NavigateParamsProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      colorCode: null,
      displayName: null,
      relatedEntity: null,
      navigationEnabled: false,
    };
  }

  private _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    this.getColorCode();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate(prevProps) {
    //console.log('rafsc componentDidUpdate',this.props,prevProps);
    if (!deepEqual(prevProps.value, this.props.value)) {
      this.getColorCode();
    }
  }

  getColorCode = () => {
    getQueryAttribute(this.props.moduleName, this.props.fieldName)
      .then((queryAttribute) => {
        if (isNotNullAndUndefined(queryAttribute)) {
          if (
            queryAttribute.DataType === RAFDataType.Dropdown &&
            this.props.isColorOption === true
          ) {
            let items = this.getDataFromChildren(queryAttribute);
            let objRow =
              isNotNullAndUndefined(items) && items.length > 0
                ? items.find((x) => x.DisplayName === this.props.value)
                : null;
            if (isNotNullAndUndefined(objRow)) {
              if (this._isMounted) {
                this.setState({
                  colorCode: objRow.ColorCode,
                  displayName: objRow.DisplayName,
                });
              }
            } else {
              if (this._isMounted) {
                this.setState({ colorCode: null, displayName: "Not set" });
              }
            }
          } else if (queryAttribute.DataType === RAFDataType.Lookup) {
            if (this._isMounted) {
              this.setState({
                navigationEnabled: true,
                relatedEntity: queryAttribute.RelatedEntities,
              });
            }
          }
        }
      })
      .catch((error) => error);
  };

  getDataFromChildren = (attributeJM?: QueryAttributeJM) => {
    let retVal: ValueJson[] = [];
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
    return retVal;
  };

  onNavigationClicked(relatedEntity: string, relatedUID: string) {
    if (
      isNotNullAndUndefined(relatedEntity) &&
      isNotNullAndUndefined(relatedUID)
    ) {
      let url =
        "/" + getURLNameByModuleName(relatedEntity) + "/view/" + relatedUID;
      if (isNotNullAndUndefined(this.props.navigate)) {
        this.props.navigate(url);
      } else {
        window.open(url);
      }
    }
  }

  render() {
    let { navigationEnabled, colorCode, displayName, relatedEntity } =
      this.state;
    let {
      mode,
      value,
      label,
      iconName,
      cssClass,
      customContent,
      showValueIcon,
      emptyString,
      isColorOption,
      customSectionOuter,
      customSectionOuterClass,
      valueUID,
      format
    } = this.props;

    if (isNotNullAndUndefined(format)) {
      switch (format) {
        case (RAFInputType.Date):
          value = isNotNullAndUndefined(value) ? moment(
            value
          ).format("DD/MM/YYYY").toString() : 'No Date';
      }
    }

    return isNotNullAndUndefined(customSectionOuter) ? (
      <div
        className={customSectionOuterClass ? customSectionOuterClass : ""}
        style={{
          ...(mode === "outlineView"
            ? {
              borderColor:
                isNotNullAndUndefined(value) &&
                  isColorOption === true &&
                  value !== "None"
                  ? colorCode
                  : "transparent",
              color:
                isNotNullAndUndefined(value) &&
                  isColorOption === true &&
                  value !== "None"
                  ? colorCode
                  : "transparent",
            }
            : {
              background:
                isNotNullAndUndefined(value) &&
                  isColorOption === true &&
                  value !== "None"
                  ? colorCode
                  : "transparent",
              color:
                isColorOption === true &&
                  isNotNullAndUndefined(colorCode) &&
                  isNotNullAndUndefined(value) &&
                  value !== "None"
                  ? "white"
                  : "#333",
            }),
        }}
      >
        {customSectionOuter}
      </div>
    ) : (
      <div
        className={
          isNotNullAndUndefined(cssClass)
            ? "d-flex align-items-center summary-section-outer " + cssClass
            : "d-flex align-items-center summary-section-outer"
        }
      >
        <div className="col summarySection p-0">
          <div className="summaryText">
            <span className="key_header">{label}</span>
            {isNotNullAndUndefined(customContent) ? (
              <div className="custom-content">{customContent}</div>
            ) : isNotNullAndUndefined(navigationEnabled) &&
              navigationEnabled === true ? (
              <div>
                <div className="key_value_div">
                  <span
                    className={"key_value"}
                    style={{
                      color:
                        isNotNullAndUndefined(value) &&
                          isNotNullAndUndefined(valueUID) &&
                          isNotNullAndUndefined(relatedEntity)
                          ? "rgb(0, 120, 214)"
                          : null,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        isNotNullAndUndefined(value) &&
                        isNotNullAndUndefined(valueUID) &&
                        isNotNullAndUndefined(relatedEntity)
                      )
                        this.onNavigationClicked(relatedEntity, valueUID);
                    }}
                  >
                    <>
                      {isNotNullAndUndefined(value) && isValidReactNode(value) ? value : "Not set"}
                    </>
                  </span>
                </div>
              </div>
            ) : isNotNullAndUndefined(mode) && mode !== "outlineView" ? (
              <div className="d-flex align-items-center colorIcon">
                {mode === "squareView" ? (
                  <i
                    className="fas fa-square"
                    style={{
                      color:
                        isColorOption === true &&
                          isNotNullAndUndefined(value) &&
                          value !== "None"
                          ? colorCode
                          : "#333",
                    }}
                  ></i>
                ) : mode === "iconView" ? (
                  <i
                    className={iconName}
                    style={{
                      color:
                        isColorOption === true &&
                          isNotNullAndUndefined(value) &&
                          value !== "None"
                          ? colorCode
                          : "#333",
                    }}
                  ></i>
                ) : (
                  <i
                    className="fas fa-circle"
                    style={{
                      color:
                        isColorOption === true &&
                          isNotNullAndUndefined(value) &&
                          value !== "None"
                          ? colorCode
                          : "#333",
                    }}
                  ></i>
                )}
                {isNotNullAndUndefined(iconName) ? (
                  <i
                    className={
                      isNotNullAndUndefined(iconName)
                        ? iconName
                        : "fas fa-circle"
                    }
                    style={{
                      color:
                        isColorOption === true &&
                          isNotNullAndUndefined(value) &&
                          value !== "None"
                          ? colorCode
                          : "#333",
                    }}
                  ></i>
                ) : null}
                <span
                  className={
                    isColorOption === true &&
                      isNotNullAndUndefined(colorCode) &&
                      value !== "None"
                      ? "custom_key_value"
                      : "key_value"
                  }
                >
                  {value !== null &&
                    value !== undefined &&
                    value.toString() !== "" &&
                    value !== "None"
                    ? String(isColorOption === true ? displayName : value)
                    : isNotNullAndUndefined(emptyString)
                      ? emptyString
                      : "Not set"}
                </span>
              </div>
            ) : (
              <div className="key_value_div">
                {mode === "outlineView" ? (
                  <span
                    style={{
                      borderColor:
                        isNotNullAndUndefined(value) &&
                          isColorOption === true &&
                          value !== "None"
                          ? colorCode
                          : "transparent",
                    }}
                    className={
                      isColorOption === true &&
                        isNotNullAndUndefined(colorCode) &&
                        isNotNullAndUndefined(value) &&
                        value !== "None"
                        ? "custom_key_value"
                        : "key_value"
                    }
                  >
                    {value !== null &&
                      value !== undefined &&
                      value.toString() !== "" &&
                      value !== "None"
                      ? String(isColorOption === true ? displayName : value)
                      : isNotNullAndUndefined(emptyString)
                        ? emptyString
                        : "Not set"}
                  </span>
                ) : (
                  <span
                    style={{
                      background:
                        isNotNullAndUndefined(value) &&
                          isColorOption === true &&
                          value !== "None"
                          ? colorCode
                          : "transparent",
                      color:
                        isColorOption === true &&
                          isNotNullAndUndefined(colorCode) &&
                          isNotNullAndUndefined(value) &&
                          value !== "None"
                          ? "white"
                          : "#333",
                    }}
                    className={
                      isColorOption === true &&
                        isNotNullAndUndefined(colorCode) &&
                        isNotNullAndUndefined(value) &&
                        value !== "None"
                        ? "custom_key_value"
                        : "key_value"
                    }
                  >
                    {value !== null &&
                      value !== undefined &&
                      value.toString() !== "" &&
                      value !== "None"
                      ? String(isColorOption === true ? displayName : value)
                      : isNotNullAndUndefined(emptyString)
                        ? emptyString
                        : "Not set"}
                  </span>
                )}
                {isNotNullAndUndefined(showValueIcon) && (
                  <span
                    className={
                      isNotNullAndUndefined(iconName) ? iconName : "fa fa-file"
                    }
                  ></span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RAFSummaryCount);
