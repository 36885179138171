import { AccountInfo } from "@azure/msal-browser";
import { Popup } from "@syncfusion/ej2-popups";
import { GridModel } from "@syncfusion/ej2-react-grids";
import {
  TabActionSettingsModel,
  TabAnimationSettingsModel,
} from "@syncfusion/ej2-react-navigations";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { RuleModel } from "@syncfusion/ej2-react-querybuilder";
import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  initializeFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import * as R from "ramda";
import React from "react";
import { msalInstance } from "../..";
import { loginRequest } from "../../RAFAzure/AuthModule";
import {
  IsNotNullOrWhiteSpace,
  clearAllCookies,
  getJSONFromSessionStorage,
  getQueryString,
  getSaveRequest,
  isArray,
  isConnectedToInternet,
  isNotEmptyArray,
  isNullOrUndefined,
  propertyOf,
} from "../../RAFComponents/helpers/utils";
import { firebaseConfig } from "../../RAFFirebase";
import { EntityRow } from "../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../RAFMaster/helpers/RMutils";
import {
  LogInAsRequestRow,
  UserDefinition,
} from "../../RAFModules/ActiveContacts/User/LogInAsRequestRow";
import { getDefaultViewsByModuleName } from "../../RAFModules/Common/List/IndexHelper";
import { submitRAFForm } from "../../RAFModules/Common/Providers/RAFObjectContextProvider";
import {
  deleteDataByUIDModuleName,
  retrieveObjectByUID,
} from "../../WorkesioRepository";
import { FormLibraryRepository } from "../../WorkesioRepository/FormLibrary/FormLibraryRepository";
import acloading from "../../assets/LoaderIconCircle.svg";
import { CareEsioEntity } from "../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  ESIOAppAdministrator,
  ESIOSuperAdministrator,
  RAFReportType,
  RAFTenantName,
  RAFViewType,
  SFColumnType,
  StorageKey,
  WorkESIOHosts,
} from "../../constants/Common/Constants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { RMStorageKey } from "../../constants/Common/RMConstants";
import { MenuCategory } from "../../constants/MenuConstant/RAFMenuCategory";
import { UserLoginLogInAs } from "../../helpers/ACutils";
import { getURLPrefixByModuleName } from "../../helpers/Common/URLPrefixByModuleName";
import { FocusRow } from "../../pages/FocusRow";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../Utility/RAFToastComponent";
import { AttributeRelatedList } from "../models/Common/AttributeRelatedList";
import { AttributeRow } from "../models/Common/AttributeRow";
import {
  ClientTerminologyJM,
  getClientTerminologiesFromStorage,
  setClientTerminologiesInStorage,
} from "../models/Common/ClientTerminologyJM";
import {
  FormLibraryRow,
  IFormLibraryDto,
} from "../models/Common/FormLibraryRow";
import {
  EntityListRequest,
  ListServiceRequest,
  ViewListRequest,
} from "../models/Common/ListRequest";
import {
  QueryAttributeJM,
  RAFOperator,
  ValueJson,
} from "../models/Common/QueryAttributeJM";
import { RAFDataType, RAFUIType } from "../models/Common/RAFDataType";
import { MenuRow, RAFMenuModel } from "../models/Common/RAFMenuModel";
import { RAFViewRow } from "../models/Common/RAFViewRow";
import { ReportJM } from "../models/Common/ReportJM";
import { TenantRow } from "../models/Common/TenantRow";
import { LookUpRow, RelatedTo } from "../models/CompositeTypes/LookUpRow";
import * as repositoryActions from "./../../RAFComponents/store/actions/repositoryActions";
import { postDataAndGetResponse } from "./../../RAFComponents/store/actions/repositoryActions";
import { removeFromFocusList } from "./RAFMenuHelper";
import {
  getRAFOperatorBySFOperator,
  getSFOperatorByRAFOperator,
} from "./SFGridUtils";
import { RAFSort } from "./types";
import { Guid, IsNullOrWhiteSpace, isNotNullAndUndefined } from "./utils";

export enum RAFActionMessage {
  RecordSaved = "Record saved successfully",
  RecordNotSaved = "Apologies, we're unable to save the record at the moment. Please try again later.!",
  RecordDeleted = "Record deleted successfully",
  RecordNotDeleted = "Apologies, we're unable to delete the record at the moment. Please try again later.!",
}

export const isCurrentTenantCareESIOPartner = () => {
  const tenantSourcePartners = JSON.parse(
    getSessionStorage(StorageKey.TenantSourcePartners, true)
  );
  const isCareESIOPartner =
    isNotEmptyArray(tenantSourcePartners) &&
    isNotNullAndUndefined(
      tenantSourcePartners.find(
        (x) =>
          isNotNullAndUndefined(x.SourceTenant) &&
          x.SourceTenant.toLowerCase() === RAFTenantName.CareESIO.toLowerCase()
      )
    )
      ? true
      : false;
  return isCareESIOPartner;
};

export const getClientTerminologies = (moduleName) => {
  //let url = moduleName + '/ClientTerminologies'
  let url = "ClientTerminology/List";
  return new Promise<ClientTerminologyJM[]>((resolve) => {
    const empClientTerms = getClientTerminologiesFromStorage(
      StorageKey.clterms_modulename + moduleName
    );

    if (isNotEmptyArray(empClientTerms)) {
      const uniqClientTerms: ClientTerminologyJM[] = R.uniq(empClientTerms);
      resolve(uniqClientTerms);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityName: moduleName },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          const empClientTerms: ClientTerminologyJM[] = response.data.Entities;
          setClientTerminologiesInStorage(
            StorageKey.clterms_modulename + moduleName,
            empClientTerms
          );
          const uniqClientTerms: ClientTerminologyJM[] = R.uniq(empClientTerms);
          resolve(uniqClientTerms);
        });
    }
  });
};

export const getReportListByModuleName = (moduleName: string) => {
  let url = `Report/List`;
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(moduleName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<ReportJM>("Entity");
  relatedFilter.Rules.push(filter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_report";

  return new Promise<ReportJM[]>((resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      const allReportViewStorage = getSessionStorage(
        StorageKey.allReportView_modulename + moduleName,
        true
      );
      if (
        isNotNullAndUndefined(allReportViewStorage) &&
        !IsNullOrWhiteSpace(allReportViewStorage) &&
        JSON.parse(allReportViewStorage).length > 0
      ) {
        const reportList: ReportJM[] = JSON.parse(allReportViewStorage);
        resolve(reportList);
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            const reportList: ReportJM[] =
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
                ? response.data.Entities
                : null;
            if (isNotNullAndUndefined(reportList) && reportList.length > 0) {
              setSessionStorage(
                StorageKey.allReportView_modulename + moduleName,
                true,
                JSON.stringify(reportList)
              );
              resolve(reportList);
            } else {
              removeSessionStorage(
                StorageKey.allReportView_modulename + moduleName
              );
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const getReportListByReportType = (
  moduleName: string,
  reportType: string
) => {
  let url = `Report/List`;
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(moduleName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<ReportJM>("Entity");
  relatedFilter.Rules.push(filter1);

  let filter2: RAFCustomFilter = {};
  let filterVal2: string[] = [];
  filterVal2.push(reportType);
  filter2.Operator = RAFCustomOperator.Equal;
  filter2.Value = filterVal2;
  filter2.Field = propertyOf<ReportJM>("ReportType");
  relatedFilter.Rules.push(filter2);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_report";

  return new Promise<ReportJM[]>((resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      const allReportViewStorage = getSessionStorage(
        StorageKey.allReportView_modulename_reportType +
          reportType +
          "_" +
          moduleName,
        true
      );
      if (
        isNotNullAndUndefined(allReportViewStorage) &&
        !IsNullOrWhiteSpace(allReportViewStorage) &&
        JSON.parse(allReportViewStorage).length > 0
      ) {
        const reportList: ReportJM[] = JSON.parse(allReportViewStorage);
        resolve(reportList);
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            const reportList: ReportJM[] =
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
                ? response.data.Entities
                : null;
            if (isNotNullAndUndefined(reportList) && reportList.length > 0) {
              setSessionStorage(
                StorageKey.allReportView_modulename_reportType +
                  reportType +
                  "_" +
                  moduleName,
                true,
                JSON.stringify(reportList)
              );
              resolve(reportList);
            } else {
              removeSessionStorage(
                StorageKey.allReportView_modulename_reportType +
                  reportType +
                  "_" +
                  moduleName
              );
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export function getDependenciesByViewID(uid: string) {
  return new Promise<RelatedTo[]>((resolve) => {
    if (isNotNullAndUndefined(uid)) {
      return repositoryActions
        .postDataAndGetResponse(
          "View/Dependencies",
          { EntityId: uid },
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export const hasDefaultModuleView = (ModuleName: string) => {
  //returns true for default modules
  let hasView = false;
  if (!IsNullOrWhiteSpace(ModuleName)) {
    const moduleName = ModuleName.toLocaleLowerCase();
    switch (moduleName) {
      case RAFEntityName.Task:
      case RAFEntityName.Portal:
      case RAFEntityName.Category:
      case RAFEntityName.FormLibraryStatic:
      case RAFEntityName.FormLibraryAdmin:
      case RAFEntityName.ContentLibrary:
      case RAFEntityName.StandardizedLibrary:
      case CareEsioEntity.CareShiftLog.EntityName:
      case CareEsioEntity.XeroIntegration.EntityName:
      case CareEsioEntity.ChecklistItemTrans.EntityName:
      case RAFEntityName.ServiceTransaction:
      case RAFEntityName.Role:
      case RAFEntityName.Team:
      //case RAFEntityName.User:
      //case RAFEntityName.Template:
      case "supportlog":
      case "billing":
      case "payroll":
      case "xerosync":
      case "datalist":
      case "task_action_center":
      case "reports":
        hasView = true;
        break;
      default:
        hasView = false;
        break;
    }
  }

  return hasView;
};

export const isDisableDashboardAnalytic = (ModuleName: string) => {
  //returns true for default modules
  let isDisableDashboardAnalytic = false;
  if (!IsNullOrWhiteSpace(ModuleName)) {
    const moduleName = ModuleName.toLocaleLowerCase();
    switch (moduleName) {
      case RAFEntityName.Task:
      case RAFEntityName.Portal:
      case RAFEntityName.Category:
      case RAFEntityName.FormLibraryStatic:
      case RAFEntityName.FormLibraryAdmin:
      case RAFEntityName.ContentLibrary:
      case RAFEntityName.Template:
      case RAFEntityName.StandardizedLibrary:
      case CareEsioEntity.CareShiftLog.EntityName:
      case CareEsioEntity.XeroIntegration.EntityName:
      case CareEsioEntity.ChecklistItemTrans.EntityName:
      case CareEsioEntity.ChecklistTemplate.EntityName:
      case RAFEntityName.ServiceTransaction:
      case RAFEntityName.Email:
      case RAFEntityName.Role:
      case RAFEntityName.Team:
      case RAFEntityName.User:
      case RAFEntityName.AcknowledgementTracking:
      case RAFEntityName.FormLibrary:
      case RAFEntityName.ProcessLibrary:
      case RAFEntityName.Employee:
      case RAFEntityName.EmployeeLeave:
      case "supportlog":
      case "billing":
      case "payroll":
      case "xerosync":
      case "datalist":
      case "task_action_center":
      case "reports":
        isDisableDashboardAnalytic = true;
        break;
      default:
        isDisableDashboardAnalytic = false;
        break;
    }
  }

  return isDisableDashboardAnalytic;
};

///showInlist true --- isPublic && isPrivate && createdBy === loggedinUser --- viewType defult or group
export const getUserViewsByModuleName = (
  moduleName: string,
  //defaultView?: RAFViewRow,
  addEmptyView?: boolean,
  filterListViews?: boolean //default true
) => {
  return new Promise<RAFViewRow[]>(async (resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      if (hasDefaultModuleView(moduleName)) {
        let defaultViews: RAFViewRow[] = [];
        const queryAttribute =
          moduleName === RAFEntityName.StandardizedLibrary
            ? await getQueryAttribute(moduleName, "Type")
            : null;

        if (
          isNotNullAndUndefined(queryAttribute) &&
          isNotEmptyArray(queryAttribute.ValueJson)
        ) {
          const valueJson = queryAttribute.ValueJson;
          const viewValueJsonItems = valueJson.map((item) => {
            let displayName = item.DisplayName;
            if (item.DisplayName === "Activity") {
              displayName = "Support Category";
            } else if (item.DisplayName === "Support") {
              displayName = "Support Needs";
            }
            return {
              UID: null,
              ViewName: item.DisplayName,
              DisplayName: displayName,
              ViewType: RAFReportType.Table,
              SettingsJson: {},
              IsSystem: true,
            };
          });
          defaultViews = viewValueJsonItems.sort((a, b) =>
            a.DisplayName.localeCompare(b.DisplayName)
          );
          // if (IsSuperAdmin()) {
          //   const allView = {
          //     UID: null,
          //     ViewName: "allitems",
          //     DisplayName: "All",
          //     ViewType: RAFReportType.Table,
          //     SettingsJson: {},
          //     IsSystem: true,
          //   };
          //   if (isNotEmptyArray(defaultViews)) {
          //     defaultViews.unshift(allView);
          //   } else {
          //     defaultViews = [allView];
          //   }
          // }
        }
        if (moduleName === CareEsioEntity.CareShiftLog.EntityName) {
          const allForms = await getAllFormsByCategoryType(
            CareEsioEntity.CareShiftLog.DisplayName
          );
          if (isNotEmptyArray(allForms)) {
            const shiftLogViews = allForms.map(async (item) => {
              let allViews = await getAllViewsByModuleName(item.Entity);
              const defaultView = isNotEmptyArray(allViews)
                ? allViews.find((x) => x.ViewType === RAFViewType.Default)
                : null;
              return {
                UID: isNotNullAndUndefined(defaultView)
                  ? defaultView.UID
                  : null,
                ViewName: item.Entity,
                DisplayName: item.Title,
                ViewType: RAFReportType.Table,
                SettingsJson: {},
                IsSystem: true,
              };
            });
            return Promise.all(shiftLogViews).then(async (shiftLogViews) => {
              if (isNotEmptyArray(shiftLogViews)) {
                resolve(shiftLogViews);
              } else {
                const userViewDropdownItem = await filterUserViewDropdownItem(
                  moduleName,
                  addEmptyView,
                  [],
                  filterListViews
                );
                resolve(userViewDropdownItem);
              }
            });
          }
        }
        if (isNotEmptyArray(defaultViews)) {
          resolve(defaultViews);
        } else {
          const userViewDropdownItem = await filterUserViewDropdownItem(
            moduleName,
            addEmptyView,
            [],
            filterListViews
          );
          resolve(userViewDropdownItem);
        }
      } else {
        const viewsStorage = getSessionStorage(
          StorageKey.allViews_modulename + moduleName,
          true
        );
        const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
          ? JSON.parse(viewsStorage)
          : null;
        if (isNotEmptyArray(allViews)) {
          let userViewDropdownItem = await filterUserViewDropdownItem(
            moduleName,
            addEmptyView,
            allViews,
            filterListViews
          );
          resolve(userViewDropdownItem);
        } else {
          let url = `${Constants.baseAPIUrl}View/ListAll`;
          let viewListRequest = new ViewListRequest();
          viewListRequest.EntityName = moduleName;
          return repositoryActions
            .postDataAndGetResponse(
              url,
              viewListRequest,
              null,
              ContentType.applicationJson
            )
            .then(async (response) => {
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotEmptyArray(response.data.Entities)
              ) {
                const allViews: RAFViewRow[] = response.data.Entities;
                setSessionStorage(
                  StorageKey.allViews_modulename + moduleName,
                  true,
                  JSON.stringify(allViews)
                );
                const userViewDropdownItem = filterUserViewDropdownItem(
                  moduleName,
                  addEmptyView,
                  allViews,
                  filterListViews
                );
                resolve(userViewDropdownItem);
              } else {
                let defaultViews: RAFViewRow[] =
                  addEmptyView === true
                    ? getDefaultViewsByModuleName(moduleName)
                    : [];
                resolve(defaultViews);
              }
            });
        }
      }
    } else {
      resolve(null);
    }
  });
};

export const getTaskUserViewsByModuleName = (
  moduleName: string,
  //defaultView?: RAFViewRow,
  addEmptyView?: boolean,
  filterListViews?: boolean
) => {
  return new Promise<RAFViewRow[]>(async (resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      const viewsStorage = getSessionStorage(
        StorageKey.allViews_modulename + moduleName,
        true
      );
      const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
        ? JSON.parse(viewsStorage)
        : null;
      if (isNotEmptyArray(allViews)) {
        let userViewDropdownItem = await filterUserViewDropdownItem(
          moduleName,
          addEmptyView,
          allViews,
          filterListViews
        );
        resolve(userViewDropdownItem);
      } else {
        let url = `${Constants.baseAPIUrl}View/ListAll`;
        let viewListRequest = new ViewListRequest();
        viewListRequest.EntityName = moduleName;
        return repositoryActions
          .postDataAndGetResponse(
            url,
            viewListRequest,
            null,
            ContentType.applicationJson
          )
          .then(async (response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotEmptyArray(response.data.Entities)
            ) {
              const allViews: RAFViewRow[] = response.data.Entities;
              setSessionStorage(
                StorageKey.allViews_modulename + moduleName,
                true,
                JSON.stringify(allViews)
              );
              const userViewDropdownItem = filterUserViewDropdownItem(
                moduleName,
                addEmptyView,
                allViews,
                filterListViews
              );
              resolve(userViewDropdownItem);
            } else {
              let defaultViews: RAFViewRow[] =
                addEmptyView === true
                  ? getDefaultViewsByModuleName(moduleName)
                  : [];
              resolve(defaultViews);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export function filterUserViewDropdownItem(
  moduleName: string,
  addEmptyView: boolean,
  allViews: RAFViewRow[],
  filterListViews: boolean //default true
) {
  let defaultViews: RAFViewRow[] =
    addEmptyView === true ? getDefaultViewsByModuleName(moduleName) : [];
  if (isNotEmptyArray(allViews)) {
    let dropDownItems: RAFViewRow[] = [];
    const loggedinUserId = msalInstance.currentUserId;
    allViews.forEach((item) => {
      if (
        isNotNullAndUndefined(item.SettingsJson) &&
        (item.SettingsJson.IsPrivate === false ||
          isNullOrUndefined(item.SettingsJson.IsPrivate) ||
          (item.SettingsJson.IsPrivate === true &&
            isNotNullAndUndefined(item.CreatedByUID) &&
            item.CreatedByUID === loggedinUserId))
        //  &&
        // item.ViewType === RAFViewType.Default
      ) {
        dropDownItems.push(item);
      }
    });
    if (isNotEmptyArray(dropDownItems)) {
      if (isNotNullAndUndefined(filterListViews) && filterListViews === false) {
        return dropDownItems;
      } else {
        let listViewItems = dropDownItems.filter(
          (x) => x.SettingsJson.ShowInList === true
        );
        if (isNotEmptyArray(listViewItems)) {
          const sortOrder = listViewItems.sort((a, b) => {
            if (a.IsSystem && !b.IsSystem) {
              return -1;
            }
            if (!a.IsSystem && b.IsSystem) {
              return 1;
            }
            return 0;
          });
          return sortOrder;
        } else {
          return defaultViews;
        }
      }
    } else {
      return defaultViews;
    }
  } else {
    return defaultViews;
  }
}

export const getAllViewsByModuleName = (moduleName: string) => {
  return new Promise<RAFViewRow[]>((resolve) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
      const allViewsStorage = getSessionStorage(
        StorageKey.allViews_modulename + moduleName,
        true
      );
      if (
        isNotNullAndUndefined(allViewsStorage) &&
        !IsNullOrWhiteSpace(allViewsStorage)
      ) {
        const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
        resolve(allViews);
      } else {
        let url = `${Constants.baseAPIUrl}View/ListAll`;
        let viewListRequest = new ViewListRequest();
        viewListRequest.EntityName = moduleName;
        return repositoryActions
          .postDataAndGetResponse(
            url,
            viewListRequest,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            const allViews: RAFViewRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allViews_modulename + moduleName,
              true,
              JSON.stringify(allViews)
            );
            resolve(allViews);
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const getViewById = (viewId?: string, moduleName?: string) => {
  let rafViewRow: RAFViewRow = new RAFViewRow();
  return new Promise<RAFViewRow>((resolve) => {
    if (IsNullOrWhiteSpace(viewId)) {
      resolve(null);
    }
    const allViewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + moduleName,
      true
    );
    if (
      isNotNullAndUndefined(allViewsStorage) &&
      !IsNullOrWhiteSpace(allViewsStorage)
    ) {
      const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
      if (isNotNullAndUndefined(allViews)) {
        rafViewRow = allViews.find((x) => x.UID === viewId);
      }
      if (isNotNullAndUndefined(rafViewRow)) {
        resolve(rafViewRow);
      } else {
        resolve(null);
      }
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          `${Constants.baseAPIUrl}View/Retrieve`,
          { EntityId: viewId },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            rafViewRow = response.data.Entity;
            resolve(rafViewRow);
          } else {
            resolve(null);
          }
        });
    }
  });
};

export const getViewByName = (viewName?: string, moduleName?: string) => {
  let rafViewRow: RAFViewRow = new RAFViewRow();
  let listRequest: ViewListRequest = new ViewListRequest();
  return new Promise<RAFViewRow>((resolve) => {
    const allViewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + moduleName,
      true
    );
    if (
      isNotNullAndUndefined(allViewsStorage) &&
      !IsNullOrWhiteSpace(allViewsStorage)
    ) {
      const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
      if (isNotNullAndUndefined(allViews)) {
        rafViewRow = allViews.find((x) => x.ViewName === viewName);
      }
      if (isNotNullAndUndefined(rafViewRow)) {
        resolve(rafViewRow);
      }
    } else {
      listRequest.EntityName = moduleName;
      return repositoryActions
        .postDataAndGetResponse(
          `${Constants.baseAPIUrl}View/ListAll`,
          listRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          let rafAllViews: RAFViewRow[] = response.data.Entities;
          setSessionStorage(
            StorageKey.allViews_modulename + moduleName,
            true,
            JSON.stringify(rafAllViews)
          );
          if (isNotNullAndUndefined(rafAllViews)) {
            rafViewRow = rafAllViews.find((x) => x.ViewName === viewName);
          }
          resolve(rafViewRow);
        });
    }
  });
};

export const getUserFocusMyList = (relatedToType: string) => {
  return new Promise<FocusRow[]>((resolve) => {
    const allUserFocusMyListStorage = getSessionStorage(
      StorageKey.allUserFocus_MyList_modulename + relatedToType,
      true
    );
    if (
      isNotNullAndUndefined(allUserFocusMyListStorage) &&
      !IsNullOrWhiteSpace(allUserFocusMyListStorage)
    ) {
      const allUserFocusMyList: FocusRow[] = JSON.parse(
        allUserFocusMyListStorage
      );
      if (isNotNullAndUndefined(allUserFocusMyList)) {
        resolve(allUserFocusMyList);
      }
    } else {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(relatedToType);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<FocusRow>("RelatedToType");
      relatedFilter.Rules.push(filter1);

      listServiceRequest.CustomFilter = relatedFilter;

      return repositoryActions
        .postDataAndGetResponse(
          `${Constants.baseAPIUrl}UserFocus/MyList`,
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allUserFocusMyList: FocusRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allUserFocus_MyList_modulename + relatedToType,
              true,
              JSON.stringify(allUserFocusMyList)
            );

            resolve(allUserFocusMyList);
          } else {
            resolve(null);
          }
        });
    }
  });
};

export const RetrieveRecord = (
  recordId: string,
  moduleName: string,
  entityUID?: string,
  defaultValue?: string,
  customurl?: string
) => {
  return new Promise<any>((resolve) => {
    if (
      isNotNullAndUndefined(recordId) &&
      isNotNullAndUndefined(moduleName) &&
      !IsNullOrWhiteSpace(recordId)
    ) {
      retrieveObjectByUID(recordId, moduleName).then((objRafObjectFromDB) => {
        if (
          isNotNullAndUndefined(objRafObjectFromDB) &&
          !IsNullOrWhiteSpace(objRafObjectFromDB.UID)
        ) {
          resolve(objRafObjectFromDB);
        } else {
          let url =
            customurl ??
            `${getURLPrefixByModuleName(
              moduleName,
              defaultValue,
              entityUID
            )}/Retrieve`;
          return repositoryActions
            .postDataAndGetResponse(
              url,
              {
                EntityId: recordId,
                EntityUID: entityUID,
                EntityName: moduleName,
              },
              null,
              ContentType.applicationJson
            )
            .then((response) => {
              if (isNotNullAndUndefined(response)) {
                if (response.status === 204) {
                  resolve(null);
                } else if (
                  isNotNullAndUndefined(response.data) &&
                  isNotNullAndUndefined(response.data.Entity)
                ) {
                  resolve(response.data.Entity);
                } else if (
                  response.data &&
                  response.data.ResultTable &&
                  response.data.ResultTable.length > 0
                ) {
                  resolve(response.data.ResultTable[0]);
                } else {
                  resolve(null);
                }
              } else {
                resolve(null);
              }
            });
        }
      });
    } else {
      resolve(null);
    }
  });
};

export const DeleteRecord = (
  recordIds: any,
  moduleName: string,
  entityUID?: string,
  defaultValue?: string
) => {
  return new Promise<Boolean>((resolve) => {
    if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
      const recordIdsArray: string[] = recordIds.split(",");

      if (isNotNullAndUndefined(recordIdsArray)) {
        let promiseArr = recordIdsArray.map(function (item) {
          return deleteDataByUIDModuleName(moduleName, item);
        });

        Promise.all(promiseArr).then(function (resultsArray) {
          return repositoryActions
            .postDataAndGetResponse(
              `${getURLPrefixByModuleName(
                moduleName,
                defaultValue,
                entityUID
              )}/Delete`,
              {
                EntityId: recordIds,
                EntityUID: entityUID,
                EntityName: moduleName,
              },
              null,
              ContentType.applicationJson
            )
            .then((response) => {
              let isDeleted = false;
              let message = "";
              if (isNotNullAndUndefined(response) && response.data === true) {
                isDeleted = true;
              } else if (
                isNotNullAndUndefined(response) &&
                response.data === false
              ) {
                isDeleted = false;
              } else if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)
              ) {
                isDeleted = response.data.IsDeleted;
                message = response.data.Message;
              }
              if (isDeleted) {
                if (isNotEmptyArray(recordIdsArray)) {
                  const removeFromFocusListItems = recordIdsArray.forEach(
                    (item) => {
                      removeFromFocusList(item);
                    }
                  );
                }
                if (moduleName == RAFEntityName.TenantSettings) {
                  removeSessionStorage(StorageKey.UserTenantSettings);
                }
                resolve(isDeleted);
              } else {
                if (IsNotNullOrWhiteSpace(message)) {
                  showWarningToast(message);
                }
                resolve(isDeleted);
              }
            });
        });
      } else {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
};

export const SyncRecord = (
  recordIds: any,
  moduleName: string,
  entityUID?: string,
  defaultValue?: string
) => {
  return new Promise<Boolean>(async (resolve) => {
    if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
      console.log("SyncRecord 1", recordIds);
      let entity = await getEntityByObjectName({ ObjectName: moduleName });
      let allForms = await getAllForms();
      let selectedFormLibraryRow: FormLibraryRow =
        allForms && allForms.find((x) => x.Entity === entity.EntityName);

      const recordIdsArray: string[] = recordIds.split(",");

      if (isNotNullAndUndefined(recordIdsArray)) {
        let promiseArr = recordIdsArray.map(function (item) {
          return retrieveObjectByUID(item, moduleName).then(
            (objRafObjectFromDB) => {
              if (
                isNotNullAndUndefined(objRafObjectFromDB) &&
                !IsNullOrWhiteSpace(objRafObjectFromDB.UID)
              ) {
                return submitRAFForm(
                  objRafObjectFromDB,
                  objRafObjectFromDB.UID,
                  entity,
                  selectedFormLibraryRow
                ).then((saveAPIResponse) => {
                  return deleteDataByUIDModuleName(moduleName, item);
                });
              }
            }
          );
        });

        Promise.all(promiseArr).then(function (resultsArray) {
          console.log("SyncRecord 2", resultsArray);
          resolve(true);
        });
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

export function SaveDataList(
  objectData?: any,
  entityUID?: string,
  entityName?: string,
  customUrl?: string
) {
  let progressDiv = showProgress("body", true);
  let url = customUrl ?? "DataList/Save";
  let uid = isNotNullAndUndefined(objectData.UID) ? objectData.UID : null;
  let saveRequestData = getSaveRequest(objectData, uid);
  if (isNotNullAndUndefined(entityUID)) {
    saveRequestData.EntityUID = entityUID;
  }
  //saveRequestData.EntityId = objectData.UID;
  if (isNotNullAndUndefined(entityName)) {
    saveRequestData.EntityName = entityName;
  }

  return new Promise<{ entityId: string; objectName: string }>(
    (resolve, reject) => {
      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  );
}

// export const DeleteDataListRecord = (
//   recordIds?: any,
//   moduleName?: string,
//   entityUID?: string
// ) => {
//   return new Promise<Boolean>((resolve) => {
//     if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
//       return repositoryActions
//         .postDataAndGetResponse(
//           `DataList/Delete`,
//           { EntityId: recordIds, EntityUID: entityUID },
//           null,
//           ContentType.applicationJson
//         )
//         .then((response) => {
//           if (isNotNullAndUndefined(response) && response.data === true) {
//             resolve(true);
//           } else {
//             resolve(false);
//           }
//         })
//     } else {
//       resolve(null);
//     }
//   });
// };

export const DeleteRecordRemoveFocus = (recordIds, moduleName) => {
  return new Promise((resolve) => {
    if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
      return repositoryActions
        .postDataAndGetResponse(
          `${getURLPrefixByModuleName(moduleName, null, null)}/Delete`,
          { EntityId: recordIds },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            removeFromFocusList(recordIds).then(() => {
              resolve(response.data);
            });
          } else {
            resolve(response.data);
          }
        });
    } else {
      resolve(false);
    }
  });
};

export const getEntityIdByName = (entityName?: string) => {
  let url = `Entity/Retrieve`;
  return new Promise<string>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        { ObjectName: entityName },
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        const entityRow: EntityRow = response.data.Entity;
        if (isNotNullAndUndefined(entityRow)) resolve(entityRow.UID);
        else resolve(null);
      });
  });
};

export const getEntityListReportEnabled = () => {
  let url = `Entity/ListReportEnabled`;
  return new Promise<EntityRow[]>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        {
          Skip: 0,
          Take: 10,
        },
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};

export async function getFields(
  moduleName?: string,
  viewId?: string
): Promise<QueryAttributeJM[]> {
  const isOnline = await isConnectedToInternet();
  // if (isOnline === true) {
  return getFieldsAPI(moduleName, viewId);
  // } else {
  //   return getFieldsFB(moduleName, viewId);
  // }
}

export const getFieldsAPI = (moduleName?: string, viewId?: string) => {
  let url = `${Constants.baseAPIUrl}Attribute/ListFields`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityName = moduleName;
  return new Promise<QueryAttributeJM[]>((resolve) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
      const viewAttributesStorage = getSessionStorage(
        StorageKey.viewAttribs_modulename +
          (isNotNullAndUndefined(viewId) ? viewId : moduleName),
        true
      );
      //console.log('viewAttribs_' + (typeName));
      if (
        isNotNullAndUndefined(viewAttributesStorage) &&
        !IsNullOrWhiteSpace(viewAttributesStorage)
      ) {
        const viewAttributes: QueryAttributeJM[] = JSON.parse(
          viewAttributesStorage
        );
        if (isNotNullAndUndefined(viewAttributes)) {
          const uniqViewAttributes: QueryAttributeJM[] = R.uniq(viewAttributes);
          resolve(uniqViewAttributes);
        } else {
          resolve(viewAttributes);
        }
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            //`${Constants.baseAPIUrl}${moduleName}/Fields`,// if viewId is give change the url accordingly
            url,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const viewAttributes: QueryAttributeJM[] = response.data.Entities;
              //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
              setSessionStorage(
                StorageKey.viewAttribs_modulename +
                  (isNotNullAndUndefined(viewId) ? viewId : moduleName),
                true,
                JSON.stringify(viewAttributes)
              );

              const uniqViewAttributes: QueryAttributeJM[] =
                R.uniq(viewAttributes);
              resolve(uniqViewAttributes);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const getAllFieldsByEntityUID = (entityUID: string) => {
  let url = `${Constants.baseAPIUrl}Attribute/ListFields`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityUID = entityUID;
  return new Promise<QueryAttributeJM[]>((resolve) => {
    if (!IsNullOrWhiteSpace(entityUID)) {
      const viewAttributesStorage = getSessionStorage(
        StorageKey.viewAttribs_modulename + entityUID,
        true
      );
      //console.log('viewAttribs_' + (typeName));
      if (
        isNotNullAndUndefined(viewAttributesStorage) &&
        !IsNullOrWhiteSpace(viewAttributesStorage)
      ) {
        const viewAttributes: QueryAttributeJM[] = JSON.parse(
          viewAttributesStorage
        );
        if (isNotNullAndUndefined(viewAttributes)) {
          const uniqViewAttributes: QueryAttributeJM[] = R.uniq(viewAttributes);
          resolve(uniqViewAttributes);
        } else {
          resolve(viewAttributes);
        }
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            //`${Constants.baseAPIUrl}${moduleName}/Fields`,// if viewId is give change the url accordingly
            url,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const viewAttributes: QueryAttributeJM[] = response.data.Entities;
              //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
              setSessionStorage(
                StorageKey.viewAttribs_modulename + entityUID,
                true,
                JSON.stringify(viewAttributes)
              );

              const uniqViewAttributes: QueryAttributeJM[] =
                R.uniq(viewAttributes);
              resolve(uniqViewAttributes);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export function getFBSuffix() {
  let REACT_APP_FB_SUFFIX = "";
  if (window.location.hostname === WorkESIOHosts.dev) {
    REACT_APP_FB_SUFFIX = "-dev";
  } else if (window.location.hostname === WorkESIOHosts.beta) {
    REACT_APP_FB_SUFFIX = "-beta";
  } else if (window.location.hostname === WorkESIOHosts.localhost) {
    REACT_APP_FB_SUFFIX = "-dev";
  }
  return REACT_APP_FB_SUFFIX;
}

export async function getFieldsFB(
  moduleName?: string,
  viewId?: string
): Promise<QueryAttributeJM[]> {
  debugger;
  const app = initializeApp(firebaseConfig);
  const firestoreDatabase = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });
  // const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(
  //   process.env.REACT_APP_FB_SUFFIX
  // )
  //   ? process.env.REACT_APP_FB_SUFFIX
  //   : "";
  const REACT_APP_FB_SUFFIX = getFBSuffix();
  let queryAttributeList: QueryAttributeJM[] = [];

  const objEntity = await getEntityByObjectName({ ObjectName: moduleName });
  if (isNotNullAndUndefined(objEntity) && !IsNullOrWhiteSpace(objEntity.UID)) {
    const q = query(
      collection(
        firestoreDatabase,
        `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/view_attributes`
      ),
      where("RefUID", "==", objEntity.UID),
      orderBy("DisplayOrder", "asc")
    );

    const entitiesSnapshot = await getDocs(q);
    entitiesSnapshot.forEach((doc1) => {
      queryAttributeList.push({
        ...doc1.data(),
        //CreatedDate: doc1.get(propertyOf<EntityRow>("CreatedDate")).toDate()
      } as QueryAttributeJM);
    });
  }
  return queryAttributeList;
}

export async function getAllFieldsFB(): Promise<QueryAttributeJM[]> {
  debugger;
  const app = initializeApp(firebaseConfig);
  const firestoreDatabase = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });

  // const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(
  //   process.env.REACT_APP_FB_SUFFIX
  // )
  //   ? process.env.REACT_APP_FB_SUFFIX
  //   : "";
  const REACT_APP_FB_SUFFIX = getFBSuffix();
  let queryAttributeList: QueryAttributeJM[] = [];

  const q = query(
    collection(
      firestoreDatabase,
      `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/view_attributes`
    ),
    orderBy("DisplayOrder", "asc")
  );

  const entitiesSnapshot = await getDocs(q);
  entitiesSnapshot.forEach((doc1) => {
    queryAttributeList.push({
      ...doc1.data(),
      //CreatedDate: doc1.get(propertyOf<EntityRow>("CreatedDate")).toDate()
    } as QueryAttributeJM);
  });
  return queryAttributeList;
}

export const getAllAttributes = (moduelName?: string) => {
  return new Promise<AttributeRelatedList>((resolve) => {
    const allAttributesStorage = getSessionStorage(
      StorageKey.allAttribs_modulename + moduelName,
      true
    );
    if (
      isNotNullAndUndefined(allAttributesStorage) &&
      !IsNullOrWhiteSpace(allAttributesStorage)
    ) {
      const viewAttributes: AttributeRelatedList =
        JSON.parse(allAttributesStorage);
      resolve(viewAttributes);
    } else {
      if (!IsNullOrWhiteSpace(moduelName)) {
        let entityListRequest: EntityListRequest = new EntityListRequest();
        entityListRequest.EntityName = moduelName;
        return repositoryActions
          .postDataAndGetResponse(
            `Attribute/ListAll`,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data)
            ) {
              let attributeRelatedList: AttributeRelatedList = {
                Attributes: response.data.Attributes,
                EntityRelations: response.data.EntityRelations,
              };
              if (isNotNullAndUndefined(attributeRelatedList.Attributes)) {
                attributeRelatedList.Attributes.sort(function (x, y) {
                  return isNotNullAndUndefined(x.AttributeSettings)
                    ? x.DisplayOrder > y.DisplayOrder
                      ? 1
                      : -1
                    : -1;
                });

                attributeRelatedList.Attributes.forEach((item, index) => {
                  if (isNullOrUndefined(item.AttributeSettings)) {
                    attributeRelatedList.Attributes[index].AttributeSettings = {
                      IsRequired: false,
                      DefaultValue: "",
                      IsPrimary: false,
                      IsReadonly: false,
                      UIType: undefined,
                    };
                  }
                });

                //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
                setSessionStorage(
                  StorageKey.allAttribs_modulename + moduelName,
                  true,
                  JSON.stringify(attributeRelatedList)
                );
                resolve(attributeRelatedList);
              } else {
                resolve(attributeRelatedList);
              }
            } else {
              resolve(null);
            }
          });
      } else {
        resolve(null);
      }
    }
  });
};

export const getQueryAttribute = (moduleName: string, fieldName: string) => {
  return new Promise<QueryAttributeJM>((resolve) => {
    if (isNotNullAndUndefined(moduleName) && isNotNullAndUndefined(fieldName)) {
      getFields(moduleName).then((attribRow) => {
        let attributeRow: QueryAttributeJM[] = attribRow as QueryAttributeJM[];
        let choiceListRow: QueryAttributeJM =
          attributeRow &&
          attributeRow.find((x) => x.PropertyName === fieldName);
        resolve(choiceListRow);
      });
    } else {
      resolve(null);
    }
  });
};

export const getAttributeValueJson = (
  moduleName?: string,
  fieldName?: string
) => {
  return new Promise<ValueJson[]>((resolve) => {
    getQueryAttribute(moduleName, fieldName).then((attribRow) => {
      const valueJsonRow = attribRow && attribRow.ValueJson;
      resolve(valueJsonRow);
    });
  });
};

export const getRecordCount = (
  moduelName?: string,
  viewUID?: string,
  relatedField?: string,
  relatedFieldValue?: string,
  entityUID?: string
) => {
  // let url = `${Constants.baseAPIUrl + getURLPrefixByModuleName(moduleName)
  //   }/RelatedList`;

  let url = `${Constants.baseAPIUrl}DataList/RelatedList`;
  const listRequest: ListServiceRequest = {
    Skip: 0,
    Take: 10,
    ViewUID: viewUID,
    ViewName:
      isNotNullAndUndefined(moduelName) && isNullOrUndefined(viewUID)
        ? `related_${moduelName}`
        : null,
  };

  let additionalParams = [
    {
      key: "RelatedField",
      value: relatedField,
    },
    {
      key: "RelatedFieldValue",
      value: relatedFieldValue,
    },
  ];

  if (isNotNullAndUndefined(additionalParams) && additionalParams.length > 0) {
    additionalParams.forEach((objProp) => {
      listRequest[objProp.key] = objProp.value;
    });
  }

  if (isNotNullAndUndefined(entityUID)) {
    listRequest.EntityUID = entityUID;
  } else if (isNotNullAndUndefined(moduelName)) {
    listRequest.EntityName = moduelName;
  }

  return new Promise<number>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            resolve(response.data.TotalRows);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
  });
};

export const Logout = () => {
  let url = "User/Logout";
  return repositoryActions
    .postDataAndGetResponse(url, null, null, ContentType.applicationJson)
    .then((response) => {
      setSessionStorage(RMStorageKey.isAdmin, false, "false");
      setSessionStorage(
        RMStorageKey.currentMenuCategory,
        false,
        MenuCategory.Default
      );
      clearAllCookies();

      const account: AccountInfo | null = msalInstance.getActiveAccount();
      if (account) {
        const isMicrosoftAccount = account.idTokenClaims?.idp?.includes(
          "login.microsoftonline.com"
        );

        const logoutUrl = isMicrosoftAccount
          ? `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`
          : undefined;

        msalInstance.logoutRedirect({
          ...loginRequest,
          account,
          postLogoutRedirectUri: logoutUrl,
        });
      }
    })
    .catch((error) => {
      console.error("User/Logout failed", error);
      const account: AccountInfo | null = msalInstance.getActiveAccount();
      if (account) {
        const isMicrosoftAccount = account.idTokenClaims?.idp?.includes(
          "login.microsoftonline.com"
        );

        const logoutUrl = isMicrosoftAccount
          ? `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`
          : undefined;

        msalInstance.logoutRedirect({
          ...loginRequest,
          account,
          postLogoutRedirectUri: logoutUrl,
        });
      }
      //return error;
    });
};

export const refreshLeftMenu = (moduleName?: string) => {
  let rafMenuModelStorage: RAFMenuModel = getJSONFromSessionStorage(
    StorageKey.MenuModel,
    true
  ) as RAFMenuModel;
  let menuRow: MenuRow = rafMenuModelStorage.MainMenu.find(
    (x) => x.Name === moduleName
  );
  let recentViews =
    JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
    ) || [];
  let recentViewId = recentViews["viewId"];
  if (isNotNullAndUndefined(menuRow)) {
    if (isNotNullAndUndefined(recentViewId)) {
      removeSessionStorage("recentClickedMenu");
      window.location.href =
        window.location.origin + menuRow.Url + recentViewId;
    } else {
      getUserViewsByModuleName(moduleName).then((allViews) => {
        window.location.href =
          window.location.origin + menuRow.Url + allViews[0].UID;
      });
    }
  }
};

export const refreshLeftMenuViews = (moduleName?: string) => {
  let rafMenuModelStorage: RAFMenuModel = getJSONFromSessionStorage(
    StorageKey.MenuModel,
    true
  ) as RAFMenuModel;
  let menuRow: MenuRow = rafMenuModelStorage.MainMenu.find(
    (x) => x.Name === moduleName
  );
  if (isNullOrUndefined(menuRow)) {
    menuRow = rafMenuModelStorage.SystemMenu.find((x) => x.Name === moduleName);
  }
  let recentViews =
    JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
    ) || [];
  let recentViewId = recentViews["viewId"];
  if (isNotNullAndUndefined(menuRow)) {
    if (isNullOrUndefined(recentViewId)) {
      getUserViewsByModuleName(moduleName).then(() => {});
    }
  }
};

export const getAllUsers = () => {
  return new Promise<LookUpRow[]>((resolve) => {
    let url = `${Constants.baseAPIUrl}User/LookUp`;
    const allUsersStorage = getSessionStorage(StorageKey.allUsers, true);
    if (
      isNotNullAndUndefined(allUsersStorage) &&
      !IsNullOrWhiteSpace(allUsersStorage) &&
      isNotNullAndUndefined(JSON.parse(allUsersStorage))
    ) {
      const allUsers: LookUpRow[] = JSON.parse(allUsersStorage);
      resolve(allUsers);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { Skip: 0 },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allUsers: LookUpRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allUsers,
              true,
              JSON.stringify(allUsers)
            );
            resolve(allUsers);
          } else {
            resolve(null);
          }
        });
    }
  });
};

export const getAllUserGroups = () => {
  return new Promise<LookUpRow[]>((resolve) => {
    let url = `${Constants.baseAPIUrl}Team/LookUp`;
    const allUserGroupsStorage = getSessionStorage(
      StorageKey.allUserGroups,
      true
    );
    if (
      isNotNullAndUndefined(allUserGroupsStorage) &&
      !IsNullOrWhiteSpace(allUserGroupsStorage) &&
      isNotNullAndUndefined(JSON.parse(allUserGroupsStorage))
    ) {
      const allUserGroups: LookUpRow[] = JSON.parse(allUserGroupsStorage);
      resolve(allUserGroups);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { Skip: 0 },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allUserGroups: LookUpRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allUserGroups,
              true,
              JSON.stringify(allUserGroups)
            );
            resolve(allUserGroups);
          } else {
            resolve(null);
          }
        });
    }
  });
};

const tabActionNext: TabActionSettingsModel = {
  effect: "FadeIn",
  duration: 600,
  easing: "ease",
};
const tabActionPrevious: TabActionSettingsModel = {
  effect: "FadeIn",
  duration: 600,
  easing: "ease",
};
export const tabAnimationSettings: TabAnimationSettingsModel = {
  previous: tabActionPrevious,
  next: tabActionNext,
};

export const showProgress = (
  target?: string | HTMLElement,
  isProgressBody?: boolean,
  progressClassName?: string,
  loadingImage?: string,
  loadingText?: string
) => {
  const progressOverlayDivId = "rafProgress_" + Guid.newGuid();
  let targetDiv = document.querySelector("body") as HTMLElement;
  if (isNotNullAndUndefined(target)) {
    if (target instanceof HTMLElement) {
      targetDiv = target;
    } else {
      targetDiv = document.querySelector(target);
    }
  }
  let progressOverlayDiv = document.querySelector<HTMLElement>(
    "#" + progressOverlayDivId
  );

  if (isNotNullAndUndefined(progressOverlayDiv)) {
  } else {
    const progressElement = document.createElement("div");
    progressElement.setAttribute("id", progressOverlayDivId);
    if (isNotNullAndUndefined(isProgressBody) && isProgressBody === true) {
      progressElement.setAttribute("class", "progressBody");
    } else if (isNotNullAndUndefined(progressClassName)) {
      progressElement.setAttribute("class", progressClassName);
    } else {
      progressElement.setAttribute("class", "progressDiv");
    }
    progressElement.setAttribute("style", 'background: "transparent"');
    let loaderImage = isNotNullAndUndefined(loadingImage)
      ? loadingImage
      : acloading;
    let loadingtext = isNotNullAndUndefined(loadingText)
      ? loadingText
      : "Loading...";
    progressElement.innerHTML =
      '<div class="d-flex flex-column" > <img src=' +
      loaderImage +
      ' width="52" /> <span class="loadingText">' +
      loadingtext +
      "</span> </div>";
    if (isNotNullAndUndefined(targetDiv)) {
      targetDiv.appendChild(progressElement);
    } else {
      document.body.appendChild(progressElement);
    }
  }

  progressOverlayDiv = document.querySelector("#" + progressOverlayDivId);
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    const destination: HTMLElement = targetDiv;
    // document.querySelector<HTMLElement>(target);
    if (isNotNullAndUndefined(destination)) {
      progressOverlayDiv.style.top = destination.offsetTop.toString();
      progressOverlayDiv.style.left = destination.offsetLeft.toString();
    }
  }
  //document.body.classList.add('overflow-hidden');

  return progressOverlayDiv;
};

export const hideProgress = (progressOverlayDiv) => {
  //const progressOverlayDivId = "progressOverlayDiv";
  //let progressOverlayDiv = document.querySelector<HTMLElement>("#" + progressOverlayDivId);
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    progressOverlayDiv.remove();
  }
  //document.body.classList.remove('overflow-hidden');
};

export const hideAllProgress = () => {
  let progressOverlayDiv = document.querySelectorAll('div[id^="rafProgress_"]');
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    progressOverlayDiv.forEach((item) => {
      item.remove();
    });
  }
};

export const RafClearDialogContent = (
  dialogRef: React.MutableRefObject<DialogComponent>,
  dialogComponent?: DialogComponent
) => {
  return new Promise<boolean>((resolve) => {
    let cleared = false;
    if (
      isNotNullAndUndefined(dialogRef) &&
      isNotNullAndUndefined(dialogRef.current)
    ) {
      dialogRef.current.content = null;
      cleared = true;
    }
    if (isNotNullAndUndefined(dialogComponent)) {
      dialogComponent.content = null;
      cleared = true;
    }
    if (cleared) {
      setTimeout(() => {
        resolve(cleared);
      }, 10);
    } else {
      resolve(cleared);
    }
  });
};

let gridstates: { key: string; gridModel: GridModel; ruleModel: RuleModel }[] =
  [];
export const SetGridState = (
  key: string,
  gridModel: GridModel,
  ruleModel: RuleModel
) => {
  let objState = gridstates.find((x) => x.key === key);
  if (isNotNullAndUndefined(objState) && isNotNullAndUndefined(objState.key)) {
    gridstates.find((x) => x.key === key).gridModel = gridModel;
    gridstates.find((x) => x.key === key).ruleModel = ruleModel;
  } else {
    gridstates.push({ key: key, gridModel: gridModel, ruleModel: ruleModel });
  }
};

export const ClearGridState = () => {
  gridstates = [];
};

export const GetGridState = (
  key: string
): { gridModel: GridModel; ruleModel: RuleModel } => {
  let objState = gridstates.find((x) => x.key === key);
  if (isNotNullAndUndefined(objState) && isNotNullAndUndefined(objState.key)) {
    return { gridModel: objState.gridModel, ruleModel: objState.ruleModel };
  } else {
    return null;
  }
};

export const GetGridModelFromGridState = (key: string): GridModel => {
  let gridState = GetGridState(key);
  if (isNotNullAndUndefined(gridState)) {
    return gridState.gridModel;
  } else {
    return null;
  }
};

export const getCustomFilterBySFRules = (rule: RuleModel): RAFCustomFilter => {
  let outVal: RAFCustomFilter = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.rules && isNotNullAndUndefined(rule.rules)) {
      outVal.Condition =
        rule.condition === "and"
          ? RAFOperator.AndCondition
          : RAFOperator.OrCondition;
      outVal.Rules = [];
      const ruleModels: RuleModel[] = rule.rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.Rules.push(getCustomFilterBySFRules(ruleModels[i]));
      }
    } else {
      let objField: RAFCustomFilter = {};
      objField.Field = rule.field;
      objField.Type = rule.type;
      objField.Operator = getRAFOperatorBySFOperator(rule.operator);
      let filterVal: string[] = [];
      if (isNotNullAndUndefined(rule.value)) {
        if (isArray(rule.value)) {
          filterVal = rule.value as string[];
        } else {
          filterVal.push(rule.value.toString());
        }
      }
      objField.Value = filterVal;
      outVal = objField;
    }
  }
  return outVal;
};

export const getSFRulesByCustomFilter = (rule: RAFCustomFilter): RuleModel => {
  let outVal: RuleModel = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
      outVal.condition =
        rule.Condition === RAFOperator.AndCondition ? "and" : "or";
      outVal.rules = [];
      const ruleModels: RAFCustomFilter[] = rule.Rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.rules.push(getSFRulesByCustomFilter(ruleModels[i]));
      }
    } else {
      let objField: RuleModel = {};
      objField.field = rule.Field;
      objField.type =
        rule.Type === SFColumnType.datetime ? SFColumnType.date : rule.Type;
      objField.operator = getSFOperatorByRAFOperator(rule.Operator);
      if (
        isNotNullAndUndefined(rule.Value) &&
        isNotNullAndUndefined(rule.Value[0])
      )
        objField.value = rule.Value.length > 1 ? rule.Value : rule.Value[0];
      outVal = objField;
    }
  }
  return outVal;
};

export function getSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean
) {
  let outVal;
  if (IsNotNullOrWhiteSpace(storageKey)) {
    if (
      isNotNullAndUndefined(includeDomainName) &&
      includeDomainName === true
    ) {
      outVal = sessionStorage.getItem(
        msalInstance.currentTenantName + storageKey
      );
    } else {
      outVal = sessionStorage.getItem(storageKey);
    }
  }
  return outVal;
}

const canSaveInSessionStorage = (value) => {
  let canSave = true;
  //write the code to check the size of the local storage

  if (IsNotNullOrWhiteSpace(value) && typeof value === "string") {
    const JSONLength =
      isNotNullAndUndefined(value) && value.length > 0 ? value.length : 0;
    if (JSONLength > 4000000) {
      canSave = false;
    }
  }
  return canSave;
};

export function setSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean,
  value?: any
) {
  if (IsNotNullOrWhiteSpace(storageKey)) {
    const canSave = canSaveInSessionStorage(value);
    if (canSave) {
      if (
        isNotNullAndUndefined(includeDomainName) &&
        includeDomainName === true
      ) {
        try {
          sessionStorage.setItem(
            msalInstance.currentTenantName + storageKey,
            value
          );
        } catch (e) {
          //console.log("Local Storage is full, Please empty data");
          // fires When localstorage gets full
          // you can handle error here or empty the local storage
        }
      } else {
        try {
          sessionStorage.setItem(storageKey, value);
        } catch (e) {}
      }
    }
  }
}

export function removeSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean
) {
  if (IsNotNullOrWhiteSpace(storageKey)) {
    if (
      isNotNullAndUndefined(includeDomainName) &&
      includeDomainName === false
    ) {
      sessionStorage.removeItem(storageKey);
    } else {
      sessionStorage.removeItem(msalInstance.currentTenantName + storageKey);
    }
  }
}

export function getViewMenuDataSource(moduleName?: string) {
  return new Promise<RAFViewRow[]>((resolve) => {
    getUserViewsByModuleName(moduleName).then((allViews) => {
      getReportListByModuleName(moduleName).then((allReportsResponse) => {
        const rafAllViews = allViews;
        let viewMenuDataSource: RAFViewRow[] = [];
        if (isNotNullAndUndefined(rafAllViews) && rafAllViews.length > 0) {
          rafAllViews.forEach((item) => {
            let viewData = { UID: item.UID, DisplayName: item.DisplayName };
            viewMenuDataSource.push(viewData);
          });
        }

        if (
          isNotNullAndUndefined(allReportsResponse) &&
          allReportsResponse.length > 0
        ) {
          allReportsResponse.forEach((item) => {
            let reportData = { UID: item.UID, DisplayName: item.Title };
            viewMenuDataSource.push(reportData);
          });
        }
        resolve(viewMenuDataSource);
      });
    });
  });
}

export async function getAllForms(): Promise<FormLibraryRow[]> {
  const isOnline = await isConnectedToInternet();
  //if (isOnline === true) {
  return getAllFormsAPISessionStorage();
  // } else {
  //   return getAllFormsFB();
  // }
}

export const getAllFormsAPISessionStorage = () => {
  let url = `FormLibrary/ListAll`;
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  let sortBy = {
    field: propertyOf<FormLibraryRow>("Title"),
    order: "ascending",
  };
  let sortQuery: string[] = [];
  if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
    sortQuery.push(
      sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );
  }
  listServiceRequest.Sort = sortQuery;

  //listServiceRequest.ViewName = "all_form_library";
  return new Promise<FormLibraryRow[]>((resolve) => {
    const allFormsStorage = getSessionStorage(RMStorageKey.all_Forms, true);
    if (
      isNotNullAndUndefined(allFormsStorage) &&
      !IsNullOrWhiteSpace(allFormsStorage) &&
      isNotNullAndUndefined(JSON.parse(allFormsStorage))
    ) {
      const allForms: FormLibraryRow[] = JSON.parse(allFormsStorage);
      resolve(allForms);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allFormsDto: IFormLibraryDto[] = response.data.Entities;
            const allForms: FormLibraryRow[] = [];

            if (isNotNullAndUndefined(allFormsDto) && allFormsDto.length > 0) {
              allFormsDto.forEach((item) => {
                allForms.push({ ...item });
              });
            }

            if (isNotNullAndUndefined(allForms) && allForms.length > 0) {
              setSessionStorage(
                RMStorageKey.all_Forms,
                true,
                JSON.stringify(allForms)
              );
            }
            resolve(allForms);
          } else {
            resolve(null);
          }
        });
    }
  });
};

export const getAllFormsAPIRxDB = async () => {
  //call getAllFormLibrary from FormLibraryRepository
  const allForms = await FormLibraryRepository.getAllFormLibrary();

  if (allForms.length > 0) {
    return allForms;
  } else {
    let url = `FormLibrary/ListAll`;
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    let sortBy = {
      field: propertyOf<FormLibraryRow>("Title"),
      order: "ascending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
      sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      );
    }
    listServiceRequest.Sort = sortQuery;

    const response = await repositoryActions.postDataAndGetResponse(
      url,
      listServiceRequest,
      null,
      ContentType.applicationJson,
      false
    );

    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.data) &&
      isNotNullAndUndefined(response.data.Entities)
    ) {
      const allFormsDto: IFormLibraryDto[] = response.data.Entities;

      if (isNotNullAndUndefined(allFormsDto) && allFormsDto.length > 0) {
        await Promise.all(
          allFormsDto.map((item) =>
            FormLibraryRepository.insertRAFFormLibrary(item)
          )
        );
      }

      return allFormsDto;
    } else {
      return null;
    }
  }
};

export const getAllFormsByCategoryType = (categoryType?: string) => {
  return new Promise<FormLibraryRow[]>((resolve) => {
    if (IsNotNullOrWhiteSpace(categoryType)) {
      const allCategoryFormsStorage = getSessionStorage(
        RMStorageKey.FormLibraryCategoryType + categoryType,
        true
      );

      const allCategoryForms: FormLibraryRow[] = IsNotNullOrWhiteSpace(
        allCategoryFormsStorage
      )
        ? JSON.parse(allCategoryFormsStorage)
        : null;

      if (isNotEmptyArray(allCategoryForms)) {
        resolve(allCategoryForms);
      } else {
        let url = `FormLibrary/List`;
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        let relatedFilter: RAFCustomFilter = {};
        relatedFilter.Condition = "and";
        relatedFilter.Rules = [];

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(categoryType);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<FormLibraryRow>("CategoryType");
        relatedFilter.Rules.push(filter1);

        listServiceRequest.CustomFilter = relatedFilter;

        let sortBy = {
          field: propertyOf<FormLibraryRow>("Title"),
          order: "ascending",
        };
        let sortQuery: string[] = [];
        if (
          isNotNullAndUndefined(sortBy) &&
          isNotNullAndUndefined(sortBy.field)
        ) {
          sortQuery.push(
            sortBy.order === "descending"
              ? `${sortBy.field} desc`
              : sortBy.field
          );
        }
        listServiceRequest.Sort = sortQuery;

        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotEmptyArray(response.data.Entities)
            ) {
              const allCategoryForms: FormLibraryRow[] = response.data.Entities;

              setSessionStorage(
                RMStorageKey.FormLibraryCategoryType + categoryType,
                true,
                JSON.stringify(allCategoryForms)
              );
              resolve(allCategoryForms);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const getAllFormLibraryListByCategory = (
  relatedToType?: string,
  categoryType?: string
) => {
  return new Promise<FormLibraryRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let isActiveFilter: RAFCustomFilter = {};
    let isActiveFilterValue: string[] = [];
    isActiveFilterValue.push("true");
    isActiveFilter.Operator = RAFCustomOperator.Equal;
    isActiveFilter.Value = isActiveFilterValue;
    isActiveFilter.Field = "IsActive";
    customFilter.Rules.push(isActiveFilter);

    if (isNotNullAndUndefined(relatedToType)) {
      let relatedTypeFilter: RAFCustomFilter = {};
      let relatedTypeFilterValue: string[] = [];
      relatedTypeFilterValue.push(relatedToType);
      relatedTypeFilter.Operator = RAFCustomOperator.Equal;
      relatedTypeFilter.Value = relatedTypeFilterValue;
      relatedTypeFilter.Field = "RelatedToType";
      customFilter.Rules.push(relatedTypeFilter);
    }
    if (isNotNullAndUndefined(categoryType)) {
      let categoryTypeFilter: RAFCustomFilter = {};
      let categoryTypeFilterValue: string[] = [];
      categoryTypeFilterValue.push(categoryType);
      categoryTypeFilter.Operator = RAFCustomOperator.Equal;
      categoryTypeFilter.Value = categoryTypeFilterValue;
      categoryTypeFilter.Field = "CategoryType";
      customFilter.Rules.push(categoryTypeFilter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    listServiceRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "FormLibrary/ListAll",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const getAllFormsByCategoryTypes = (
  categoryType?: string[],
  customCategory?: string
) => {
  return new Promise<FormLibraryRow[]>((resolve) => {
    if (IsNotNullOrWhiteSpace(customCategory)) {
      const allCategoryFormsStorage = getSessionStorage(
        RMStorageKey.FormLibararyCustomCategory + customCategory,
        true
      );

      const allCategoryForms: FormLibraryRow[] = IsNotNullOrWhiteSpace(
        allCategoryFormsStorage
      )
        ? JSON.parse(allCategoryFormsStorage)
        : null;

      if (isNotEmptyArray(allCategoryForms)) {
        resolve(allCategoryForms);
      } else {
        let url = `FormLibrary/List`;
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        let relatedFilter: RAFCustomFilter = {};
        relatedFilter.Condition = "or";
        relatedFilter.Rules = [];

        if (isNotEmptyArray(categoryType)) {
          categoryType.forEach((categoryType) => {
            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(categoryType);
            filter1.Operator = RAFCustomOperator.Equal;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<FormLibraryRow>("CategoryType");
            relatedFilter.Rules.push(filter1);
          });
        }
        listServiceRequest.CustomFilter = relatedFilter;

        let sortBy = {
          field: propertyOf<FormLibraryRow>("Title"),
          order: "ascending",
        };
        let sortQuery: string[] = [];
        if (
          isNotNullAndUndefined(sortBy) &&
          isNotNullAndUndefined(sortBy.field)
        ) {
          sortQuery.push(
            sortBy.order === "descending"
              ? `${sortBy.field} desc`
              : sortBy.field
          );
        }
        listServiceRequest.Sort = sortQuery;
        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const allFormsDto: IFormLibraryDto[] = response.data.Entities;
              const allForms: FormLibraryRow[] = [];

              if (
                isNotNullAndUndefined(allFormsDto) &&
                allFormsDto.length > 0
              ) {
                allFormsDto.forEach((item) => {
                  allForms.push({ ...item });
                });
              }
              setSessionStorage(
                RMStorageKey.FormLibararyCustomCategory + customCategory,
                true,
                JSON.stringify(allForms)
              );
              resolve(allForms);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const removeAllFormLibraryAndEntityItemsFromSessionStorage = () => {
  removeSessionStorage(RMStorageKey.all_Forms, true);
  const keys = Object.keys(sessionStorage);
  isNotEmptyArray(keys) &&
    keys.forEach((key) => {
      const formLibraryEntity =
        msalInstance.currentTenantName + RMStorageKey.FormLibraryEntity;
      const formLibraryCategoryType =
        msalInstance.currentTenantName + RMStorageKey.FormLibraryCategoryType;
      if (
        key.startsWith(formLibraryEntity) ||
        key.startsWith(formLibraryCategoryType)
      ) {
        if (IsNotNullOrWhiteSpace(key)) {
          sessionStorage.removeItem(key);
        }
      }
    });
};

export const getFormByEntity = (entityName?: string) => {
  return new Promise<FormLibraryRow>((resolve) => {
    if (IsNotNullOrWhiteSpace(entityName)) {
      const formLibraryEntityItem = getSessionStorage(
        RMStorageKey.FormLibraryEntity + entityName,
        true
      );

      const formLibrary: FormLibraryRow = IsNotNullOrWhiteSpace(
        formLibraryEntityItem
      )
        ? JSON.parse(formLibraryEntityItem)
        : null;

      if (
        isNotNullAndUndefined(formLibrary) &&
        isNotNullAndUndefined(formLibrary.UID)
      ) {
        resolve(formLibrary);
      } else {
        let url = `FormLibrary/List`;
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        let relatedFilter: RAFCustomFilter = {};
        relatedFilter.Condition = "and";
        relatedFilter.Rules = [];

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(entityName);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<FormLibraryRow>("Entity");
        relatedFilter.Rules.push(filter1);
        listServiceRequest.CustomFilter = relatedFilter;

        let sortBy = {
          field: propertyOf<FormLibraryRow>("Title"),
          order: "ascending",
        };
        let sortQuery: string[] = [];
        if (
          isNotNullAndUndefined(sortBy) &&
          isNotNullAndUndefined(sortBy.field)
        ) {
          sortQuery.push(
            sortBy.order === "descending"
              ? `${sortBy.field} desc`
              : sortBy.field
          );
        }
        listServiceRequest.Sort = sortQuery;

        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotEmptyArray(response.data.Entities)
            ) {
              const allForms: FormLibraryRow[] = response.data.Entities;
              const formLibrary: FormLibraryRow = allForms[0];
              if (
                isNotNullAndUndefined(formLibrary) &&
                isNotNullAndUndefined(formLibrary.UID)
              ) {
                setSessionStorage(
                  RMStorageKey.FormLibraryEntity + entityName,
                  true,
                  JSON.stringify(formLibrary)
                );
                resolve(formLibrary);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export async function getAllFormsFB() {
  const app = initializeApp(firebaseConfig);
  const firestoreDatabase = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });

  // const REACT_APP_FB_SUFFIX: string = isNotNullAndUndefined(
  //   process.env.REACT_APP_FB_SUFFIX
  // )
  //   ? process.env.REACT_APP_FB_SUFFIX
  //   : "";
  const REACT_APP_FB_SUFFIX = getFBSuffix();
  let entityList: FormLibraryRow[] = [];
  //const q = query(collection(firestoreDatabase, `users${REACT_APP_FB_SUFFIX}/${msalInstance.currentUserId}/UserFeeds`), where("IsRead", "==", true), orderBy("CreatedDate", "desc"));
  const entitiesCol = collection(
    firestoreDatabase,
    `tenants${REACT_APP_FB_SUFFIX}/${msalInstance.currentTenantId}/form_library`
  );
  const entitiesSnapshot = await getDocs(entitiesCol);
  entitiesSnapshot.forEach((doc1) => {
    entityList.push({
      ...doc1.data(),
      //CreatedDate: doc1.get(propertyOf<EntityRow>("CreatedDate")).toDate()
    } as FormLibraryRow);
  });

  return entityList;
}

export const CheckFormExist = (formName?: string) => {
  return new Promise<boolean>((resolve) => {
    getAllForms().then((allForms) => {
      let selectedFormLibraryRow: FormLibraryRow =
        allForms && allForms.find((x) => x.Title === formName);
      if (isNotNullAndUndefined(selectedFormLibraryRow)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export function GetFieldsDisplayName(
  queryAttributes: QueryAttributeJM[],
  field: string,
  defaultValue: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let displayLabel = defaultValue;
  if (isNotNullAndUndefined(defaultValue)) {
  } else {
    let fieldToSplit = field.split(".");
    let fieldString = isNotNullAndUndefined(fieldToSplit)
      ? fieldToSplit.splice(-1)
      : null;
    let fieldName = isNotNullAndUndefined(fieldString) ? fieldString[0] : field;
    const uniqueAllFields: QueryAttributeJM[] = isNotNullAndUndefined(
      queryAttributes
    )
      ? R.uniq(queryAttributes)
      : null;
    let objField: QueryAttributeJM = isNotNullAndUndefined(uniqueAllFields)
      ? uniqueAllFields.find((x) => x.PropertyName === fieldName)
      : null;

    if (isNotNullAndUndefined(objField)) {
      let attributeItem: AttributeRow =
        isNotNullAndUndefined(attributeRelatedList) &&
        isNotNullAndUndefined(attributeRelatedList.Attributes) &&
        attributeRelatedList.Attributes.length > 0
          ? attributeRelatedList.Attributes.find(
              (x) => x.UID === objField.AttributeUID
            )
          : null;

      if (
        isNotNullAndUndefined(attributeItem) &&
        isNotNullAndUndefined(attributeItem.UID) &&
        isNotNullAndUndefined(attributeItem.Description)
      ) {
        displayLabel = attributeItem.Description;
      } else if (isNotNullAndUndefined(objField.DisplayName)) {
        displayLabel = objField.DisplayName;
      } else {
        displayLabel = objField.FieldName;
      }
    } else {
      displayLabel = fieldName;
    }
  }
  return displayLabel;
}

export function GetSelectedField(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  return selectedField;
}

export function GetSelectedFieldDescription(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  if (
    isNotNullAndUndefined(selectedField) &&
    isNotNullAndUndefined(selectedField.Description)
  ) {
    return selectedField.Description;
  } else {
    return null;
  }
}

export function GetSelectedFieldDescriptionStyle(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  if (
    isNotNullAndUndefined(selectedField) &&
    isNotNullAndUndefined(selectedField.AttributeSettings) &&
    isNotNullAndUndefined(selectedField.AttributeSettings.DescriptionAsLabel) &&
    selectedField.AttributeSettings.DescriptionAsLabel === true
  ) {
    return true;
  } else {
    return false;
  }
}

export const getRelatedRecords = (
  moduleName: string,
  viewUID: string,
  relatedField: string,
  relatedFieldValue: string,
  entityUID?: string,
  entityName?: string,
  sortQuery?: string[],
  defaultValue?: string,
  customFilter?: RAFCustomFilter,
  take?: number,
  skip?: number,
  viewName?: string,
  relatedToType?: string
) => {
  return new Promise<any>((resolve) => {
    if (isNotNullAndUndefined(relatedFieldValue)) {
      let url = `${
        Constants.baseAPIUrl +
        getURLPrefixByModuleName(
          moduleName,
          defaultValue,
          entityUID,
          entityName
        )
      }/RelatedList`;

      const listRequest: ListServiceRequest = {
        Skip: skip ?? 0,
        Take: take ?? 0,
      };

      if (isNotNullAndUndefined(viewUID)) {
        listRequest.ViewUID = viewUID;
      }
      if (isNotNullAndUndefined(viewName)) {
        listRequest.ViewName = viewName;
      }

      let additionalParams = [
        {
          key: "RelatedField",
          value: relatedField,
        },
        {
          key: "RelatedFieldValue",
          value: relatedFieldValue,
        },
      ];

      if (
        isNotNullAndUndefined(additionalParams) &&
        additionalParams.length > 0
      ) {
        additionalParams.forEach((objProp) => {
          listRequest[objProp.key] = objProp.value;
        });
      }

      if (isNotNullAndUndefined(entityUID)) {
        listRequest.EntityUID = entityUID;
      }

      if (isNotNullAndUndefined(entityName)) {
        listRequest.EntityName = entityName;
      }

      if (isNotNullAndUndefined(relatedToType)) {
        listRequest["RelatedToType"] = relatedToType;
      }

      if (isNotEmptyArray(sortQuery)) {
        listRequest.Sort = sortQuery;
      }

      if (isNotNullAndUndefined(customFilter)) {
        listRequest.CustomFilter = customFilter;
      }

      return repositoryActions
        .postDataAndGetResponse(
          url,
          listRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              if (isNotNullAndUndefined(response.data.Entity)) {
                resolve(response.data.Entity);
              } else if (isNotEmptyArray(response.data.ResultTable)) {
                resolve(response.data.ResultTable);
              } else if (isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
              } else {
                resolve(null);
              }
            }
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export function navigateToExternalUrl(
  url?: string,
  shouldOpenNewTab?: boolean
) {
  if (isNotNullAndUndefined(shouldOpenNewTab) && shouldOpenNewTab === true) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}

// export const useOutsideClick = (callback) => {
//     const ref = useRef();
//     useEffect(() => {
//         const handleClick = (event) => {
//             if (ref.current && !(ref.current as any).contains(event.target)) {
//                 callback();
//             }
//         }; document.addEventListener('click', handleClick, true);
//         return () => {
//             document.removeEventListener('click', handleClick, true);
//         };
//     }, [ref]);
//     return ref;
// };

export const closeAllSFPopups = () => {
  // let eTooltipTemplate = document.getElementsByClassName('e-tooltip-template-css')
  // for (let i = 0; i < eTooltipTemplate.length; i++) {
  //     console.log("eTooltipTemplate[i]", eTooltipTemplate[i]['ej2_instances'][0])
  //     //eTooltipTemplate[i].classList.remove("e-popup-open");
  //     //eTooltipTemplate[i].classList.add("e-popup-close");

  //     // eTooltipTemplate[i]['ej2_instances'][0].element.classList.remove('e-popup-open');
  //     // eTooltipTemplate[i]['ej2_instances'][0].element.classList.add('e-popup-close');
  //     // eTooltipTemplate[i]['ej2_instances'][0].element.remove();
  // }

  //return;
  let eOpenPopups = document.getElementsByClassName("e-popup-open");
  console.log("eOpenPopups", eOpenPopups);
  for (let i = 0; i < eOpenPopups.length; i++) {
    console.log(
      "eOpenPopups[i]['ej2_instances'][0] ",
      eOpenPopups[i]["ej2_instances"][0]
    );
    try {
      const objPopup = eOpenPopups[i]["ej2_instances"][0] as Popup;
      const objElement = objPopup.element;
      const objPopupTarget = document.querySelector(
        `[data-tooltip-id*="${objElement.id}"]`
      );
      objPopupTarget.removeAttribute("data-tooltip-id");
      objPopupTarget.removeAttribute("aria-describedby");
      console.log("objPopupTarget", objPopupTarget);
      // objPopup.close();
      // objPopup.hide();
      // objPopup.refresh();
      objPopup.element.classList.remove("e-popup-open");
      objPopup.element.classList.add("e-popup-close");
      // objPopup.trigger('close');
      // objPopup.refresh();
      // objPopup.element.remove();
      // console.log('2', objPopup);
      // console.log('objPopup.element.classList', objPopup.element.classList);
    } catch (e) {
      console.log(e);
    }
  }
};

export const onBodyClick = (e) => {
  console.log("onBodyClick", e);
  if (e.target.classList.contains("e-popup")) return;
  if (isNotNullAndUndefined(e.target.closest("e-popup-open"))) return;
  closeAllSFPopups();
};
// document.body.addEventListener('click', onBodyClick);

export const useOutsideClick = (callback) => {
  const ref = React.useRef();
  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);
  return ref;
};

export const IsSuperAdmin = (): boolean => {
  const currentUser = JSON.parse(getSessionStorage(StorageKey.currentUser));
  const permissionGroup = isNotNullAndUndefined(currentUser)
    ? currentUser.PermissionGroup
    : null;
  if (
    permissionGroup === ESIOAppAdministrator ||
    permissionGroup === ESIOSuperAdministrator
  ) {
    return true;
  } else {
    return IsSuperAdminQueryString();
  }
};

export const ISESIOAppAdministrator = (): boolean => {
  const currentUser = JSON.parse(getSessionStorage(StorageKey.currentUser));
  const permissionGroup = isNotNullAndUndefined(currentUser)
    ? currentUser.PermissionGroup
    : null;
  if (permissionGroup === ESIOAppAdministrator) {
    return true;
  } else {
    return IsSuperAdminQueryString();
  }
};

export const IsSuperAdminQueryString = (): boolean => {
  const myURl = getQueryString("admin");
  if (isNotNullAndUndefined(myURl) && myURl === "1") {
    return true;
  } else {
    return false;
  }
};

export const IsAdminQueryString = (): boolean => {
  const myURl = getQueryString("admin");
  if (isNotNullAndUndefined(myURl) && myURl === "2024") {
    return true;
  } else {
    return false;
  }
};
export function getAllLookUpList(
  entityName: string,
  url: string,
  customFilter?: RAFCustomFilter,
  sort?: RAFSort
) {
  return new Promise<LookUpRow[]>((resolve) => {
    if (isNotNullAndUndefined(url)) {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      if (isNotNullAndUndefined(sort)) {
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
          sortQuery.push(
            sort.order === "descending" ? `${sort.field} desc` : sort.field
          );
        }

        listServiceRequest.Sort = sortQuery;
      }

      if (isNotNullAndUndefined(customFilter))
        listServiceRequest.CustomFilter = customFilter;

      if (isNotNullAndUndefined(isNotNullAndUndefined(entityName)))
        listServiceRequest.EntityName = entityName;

      return repositoryActions
        .postDataAndGetResponse(
          url,
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export const defaultViewDataColumns = (moduleName: string) => {
  let dataColumns: string[] = [];
  if (isNotNullAndUndefined(moduleName)) {
    if (moduleName === RAFEntityName.ContentLibrary) {
      dataColumns = [
        "FileSize",
        "IsExternal",
        "FileName",
        "Entity",
        "RecordCategory",
        "DisplayName",
        "DocumentType",
        "ParentUID",
        "CreatedBy",
        "CreatedDate",
        "LastActivityDate",
        "ModifiedDate",
        "ModifiedBy",
      ];
    }
  }

  return dataColumns;
};

export const setImpersonateUserDefinition = (
  userDefinition: UserDefinition
) => {
  setSessionStorage(
    StorageKey.userDefinition,
    false,
    JSON.stringify(userDefinition)
  );
};

export const getImpersonateUserDefinition = (): UserDefinition => {
  const userDefinition = getSessionStorage(StorageKey.userDefinition);
  const retVal: UserDefinition = JSON.parse(userDefinition);
  return retVal;
};

export const isRAFMaxDate = (dateValue, convertDateValue) => {
  let isMaxDate = false;
  if (
    isNotNullAndUndefined(dateValue) &&
    isNotNullAndUndefined(convertDateValue)
  ) {
    if (
      Constants.MaxDate.valueOf() === dateValue.valueOf() ||
      moment(dateValue).isSame(Constants.MaxDate, "day") ||
      Constants.MaxDate.valueOf() === convertDateValue.valueOf() ||
      moment(convertDateValue).isSame(Constants.MaxDate, "day")
    ) {
      isMaxDate = true;
    }
  }
  return isMaxDate;
};

export const isRAFMinDate = (dateValue, convertDateValue) => {
  let isMinDate = false;
  if (
    isNotNullAndUndefined(dateValue) &&
    isNotNullAndUndefined(convertDateValue)
  ) {
    if (
      Constants.MinDate.valueOf() === dateValue.valueOf() ||
      moment(dateValue).isSame(Constants.MinDate, "day") ||
      Constants.MinDate.valueOf() === convertDateValue.valueOf() ||
      moment(convertDateValue).isSame(Constants.MinDate, "day")
    ) {
      isMinDate = true;
    }
  }

  return isMinDate;
};

export const switchOrganisation = (selectedTenantId: string) => {
  return new Promise<boolean>((resolve) => {
    if (isNotNullAndUndefined(selectedTenantId)) {
      if (msalInstance.currentTenantId !== selectedTenantId) {
        let allUserTenants = msalInstance.currentTenants;
        let selectedTenant: TenantRow =
          allUserTenants &&
          allUserTenants.find((x) => x.UID === selectedTenantId);

        let logInAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();
        //logInAsRequest.UserUID = msalInstance.currentUserId;
        logInAsRequestRow.TenantUID = selectedTenant.UID;

        // let currentUser: UserInfoRow = JSON.parse(
        //   getSessionStorage(StorageKey.currentUser)
        // );
        // if (
        //   isNotNullAndUndefined(currentUser) &&
        //   isNotNullAndUndefined(currentUser.UserUID)
        // ) {
        //   currentUser.TenantName = selectedTenant.TenantName;
        //   currentUser.TenantUID = selectedTenant.UID;
        //   currentUser.PortalUID = null;
        //   currentUser.Portal = null;
        // }

        postDataAndGetResponse(
          "User/LogInAs",
          logInAsRequestRow,
          null,
          ContentType.applicationJson,
          false
        ).then(() => {
          window.localStorage.setItem(
            StorageKey.currentOrganisation,
            JSON.stringify({
              UID: selectedTenant.UID,
              DisplayName: selectedTenant.DisplayName,
              TenantName: selectedTenant.TenantName,
              SubDomain: selectedTenant.SubDomain,
            })
          );
          removeSessionStorage(StorageKey.currentUser, false);
          resolve(true);
        });
      } else {
        resolve(false);
      }
    }
  });
};

export const switchTenant = (
  selectedTenantId: string,
  selectedUserID: string,
  forceLogin: boolean = false //in switch portal, we need to force login but in switch tenant, we don't need to force login and just check if same tenant is selected
) => {
  return new Promise<boolean>((resolve) => {
    if (
      isNotNullAndUndefined(selectedTenantId) &&
      isNotNullAndUndefined(selectedUserID)
    ) {
      if (
        forceLogin ||
        msalInstance.currentTenantId !== selectedTenantId ||
        msalInstance.currentUserId !== selectedUserID
      ) {
        //const allUserTenants = msalInstance.currentTenants;
        // const selectedTenant: TenantRow = isNotEmptyArray(allUserTenants) ?
        //   allUserTenants.find((x) => x.UID === selectedTenantId) : null;

        // if (isNotNullAndUndefined(selectedTenant)) {
        const logInAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();
        logInAsRequestRow.EmployeeUID = selectedUserID;
        logInAsRequestRow.TenantUID = selectedTenantId;

        postDataAndGetResponse(
          "User/LogInAs",
          logInAsRequestRow,
          null,
          ContentType.applicationJson,
          false
        ).then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            try {
              const userLoginLogInAs = await UserLoginLogInAs(
                logInAsRequestRow
              );
            } catch (error) {
              //added explicityly by jagan
            }
            sessionStorage.removeItem(StorageKey.currentBusinessUnit);
            sessionStorage.removeItem(StorageKey.MenuModel);
            sessionStorage.removeItem(StorageKey.Terminologies);
            setImpersonateUserDefinition(response.data);
            const responseDataTenants = response.data.Tenants;
            const selectedTenant: TenantRow = isNotEmptyArray(
              responseDataTenants
            )
              ? responseDataTenants.find((x) => x.UID === selectedTenantId)
              : null;
            if (isNotNullAndUndefined(selectedTenant)) {
              window.localStorage.setItem(
                StorageKey.currentOrganisation,
                JSON.stringify({
                  UID: selectedTenant.UID,
                  DisplayName: selectedTenant.DisplayName,
                  TenantName: selectedTenant.TenantName,
                  SubDomain: selectedTenant.SubDomain,
                })
              );
            }
            sessionStorage.removeItem(StorageKey.CurrentPortal);
            setSessionStorage(
              StorageKey.currentUser,
              false,
              JSON.stringify(response.data)
            );
            resolve(true);
          } else {
            resolve(false);
          }
        });
        // } else {
        //   resolve(false);
        // }
      } else {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
};

export const switchPortal = (
  selectedTenantId: string,
  selectedUserID: string,
  selectedPortalID: string,
  forceLogin: boolean = false //in switch portal, we need to force login but in switch tenant, we don't need to force login and just check if same tenant is selected
) => {
  return new Promise<boolean>((resolve) => {
    if (
      isNotNullAndUndefined(selectedTenantId) &&
      isNotNullAndUndefined(selectedUserID)
    ) {
      if (
        forceLogin ||
        msalInstance.currentTenantId !== selectedTenantId ||
        msalInstance.currentUserId !== selectedUserID
      ) {
        const logInAsRequestRow: LogInAsRequestRow = new LogInAsRequestRow();
        logInAsRequestRow.EmployeeUID = selectedUserID;
        logInAsRequestRow.TenantUID = selectedTenantId;
        logInAsRequestRow.PortalUID = selectedPortalID;

        postDataAndGetResponse(
          "User/SwitchPortal",
          logInAsRequestRow,
          null,
          ContentType.applicationJson,
          false
        ).then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            try {
              const userLoginLogInAs = await UserLoginLogInAs(
                logInAsRequestRow
              );
            } catch (error) {
              //added explicityly by jagan
            }
            sessionStorage.removeItem(StorageKey.currentBusinessUnit);
            sessionStorage.removeItem(StorageKey.MenuModel);
            sessionStorage.removeItem(StorageKey.Terminologies);
            //sessionStorage.removeItem(StorageKey.all_businessUnit);
            setImpersonateUserDefinition(response.data);

            const responseDataTenants = response.data.Tenants;
            const selectedTenant: TenantRow = isNotEmptyArray(
              responseDataTenants
            )
              ? responseDataTenants.find((x) => x.UID === selectedTenantId)
              : null;
            if (isNotNullAndUndefined(selectedTenant)) {
              window.localStorage.setItem(
                StorageKey.currentOrganisation,
                JSON.stringify({
                  UID: selectedTenant.UID,
                  DisplayName: selectedTenant.DisplayName,
                  TenantName: selectedTenant.TenantName,
                  SubDomain: selectedTenant.SubDomain,
                })
              );
            }
            sessionStorage.removeItem(StorageKey.CurrentPortal);
            setSessionStorage(
              StorageKey.currentUser,
              false,
              JSON.stringify(response.data)
            );

            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
};

export function getFieldCustomFilter(
  moduleName?: string,
  formValue?: any,
  dataType?: string
) {
  let customFilter: RAFCustomFilter = {};
  customFilter.Condition = "and";
  customFilter.Rules = [];
  if (moduleName === "care_intervention_support") {
    if (dataType === RAFDataType.Lookup) {
      if (
        isNotNullAndUndefined(formValue) &&
        isNotNullAndUndefined(formValue.CareRecipientUID)
      ) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(formValue.CareRecipientUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "CareRecipientUID";
        customFilter.Rules.push(filter1);
      }
      if (
        isNotNullAndUndefined(formValue) &&
        isNotNullAndUndefined(formValue.CarePlanUID)
      ) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(formValue.CarePlanUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "CarePlanUID";
        customFilter.Rules.push(filter1);
      }
    }
    if (
      isNotNullAndUndefined(formValue) &&
      dataType === RAFUIType.Autosuggestion
    ) {
      let supportType = isNotNullAndUndefined(formValue.SupportType)
        ? formValue.SupportType
        : null;
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push("Support");
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Type";
      customFilter.Rules.push(filter);

      if (isNotNullAndUndefined(supportType)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(supportType);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "TagsListJson";
        customFilter.Rules.push(filter);
      }
    }
  }
  if (moduleName === "care_goal") {
    if (dataType === RAFUIType.Autosuggestion) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push("Goal");
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Type";
      customFilter.Rules.push(filter);
    }
  }
  if (moduleName === "shift_activity") {
    if (dataType === RAFDataType.Lookup) {
      if (
        isNotNullAndUndefined(formValue) &&
        isNotNullAndUndefined(formValue.CareRecipientUID)
      ) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(formValue.CareRecipientUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "CareRecipientUID";
        customFilter.Rules.push(filter1);
      }
    }
  }

  return customFilter;
}

export function getUnique(arr, compareField) {
  if (isNullOrUndefined(arr) || arr.length === 0) return arr;
  // store the comparison  values in array
  const unique = arr
    .map((e) => e[compareField])
    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
}

export const getAllDynamicFormsByEntityName = (entityName?: string) => {
  let url = `Entity/DynamicSubForms`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityName = entityName;
  return new Promise<EntityRow[]>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        entityListRequest,
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          const allSubFormEntities: EntityRow[] = response.data.Entities;

          resolve(allSubFormEntities);
        } else {
          resolve(null);
        }
      });
  });
};

export function isArrayOfType(arr, type) {
  return arr && arr.every((item) => typeof item === type);
}

export function isHTML(str) {
  const htmlRegex = /<[^>]*>/g;
  return htmlRegex.test(str);
}
